/*Sign In and forgot password style start*/

.imageWhiteBg {
    margin: 20px 0 0 5px;
}

.forgot-pass-container {
    height: auto;
}

.forgot-pass-row {
    margin: 0 auto;
}

.BigImage {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    height: 86vh;
}

.ContentForm {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.WhiteFormSignIn {
    background-color: #ffffff;
    border-radius: 15px;
    min-width: 450px !important;
    height: 472px;
}

.Content {
    padding: 38px
}

.GM_msg {
    font-size: 1.375rem;
    font-weight: bold;
    text-align: left;
    color: #182e37;
    margin-bottom: 0px;
}

.LinkForget {
    margin: 0px;
}

.Mask {
    width: 126px;
    height: 83px;
    margin: 22px 10px 22px 1px;
    padding: 5px 4px 4px 7px;
    border-radius: 13px;
    border: solid 0.5px #1ba9d6;
    background-color: #f2f3f8;
    float: left;
}

.Operator_label {
    font-size: 1.063rem;
    font-weight: bold;
    color: #182e37;
    margin: 0px;
}

.label_p {
    font-size: 0.75rem;
    text-align: left;
    color: #182e37;
    margin-bottom: 2px;
}

.LabelText {
    font-size: 0.875rem;
    font-weight: 600;
    color: #8a8a8a;
    margin-bottom: 2px;
    margin-top: 15px;
}

.EnterID {
    width: 25.75rem;
    height: 2.5rem;
    margin: 0.412rem 0 0;
    padding: 0.5rem;
    border-radius: 8px;
    background-color: #f0f3f5;
    border: none;
}

.EnterID:focus {
    border: none;
    outline: none !important;
}

.ForgotPassword {
    font-size: 0.875rem;
    font-weight: bold;
    text-align: left;
    color: #1a5974;
    margin-top: 1rem;
    cursor: pointer;
    width: fit-content;
}

.ForgotPassword:hover {
    text-decoration: underline;
}

.ForgotPassword1 {
    font-size: 0.875rem;
    font-weight: bold;
    text-align: left;
    color: #1a5974;
    margin-top: 20px;
}

.Manage-Configuration {
    width: 2.75rem;
    height: 1.188rem;
    font-size: 0.875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.Intellve_customer {
    font-size: 0.875rem;
    font-weight: normal;
    text-align: left;
    color: #748b94;
    margin-top: 15px;
}

.Account {
    font-size: 1rem;
    text-align: center;
    color: #182e37;
}

.Sign-up {
    font-size: 1rem;
    font-weight: bold;
    color: #182e37;
    margin-left: 10px;
}

.ErrorPassword {
    border: 1px solid #e11e24;
}

.Error_P {
    margin: 0px;
    color: #e11e24;
    font-size: 14px;
    margin-top: 2px;
}

.Error_P img {
    width: 20px;
    height: 20px;
    padding: 1rem;
}

.Error_P>svg {
    margin: 0.5rem;
}

.ForgotPasswordName {
    font-size: 1.375rem;
    font-weight: bold;
    text-align: left;
    color: #182e37;
    margin-top: 50px;
}

.ForgotPasswordName img {
    cursor: pointer;
}

.SelectSpaceLine {
    font-size: 0.875rem;
    font-weight: normal;
    text-align: left;
    color: #748b94;
    margin-right: 20px;
}

.signup-header {
    display: inline-flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.ViaSMS {
    width: 25.813rem;
    height: 5.5rem;
    margin: 40px 0px;
    padding: 1.365rem 7.438rem 1.469rem 4.125rem;
    box-shadow: 0 12px 16px 0 rgba(44, 121, 152, 0.3);
    background-image: linear-gradient(249deg, #027aaa 99%, #4ca0c2 -3%);
    border-radius: 8px;
    margin-bottom: 20px;
    border: none;
}

.ViaEmail {
    width: 100%;
    height: 5.5rem;
    border-radius: 8px;
    border: solid 1px #1a5974;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.ResetPasswordButton {
    width: 100%;
    padding: 0.6rem 0rem 0.6rem;
    border-radius: 23px;
    text-align: center;
}

.ViaSMS:focus,
.ViaEmail:focus,
.ResetPasswordButton:focus,
.CodeBorderDiv:focus,
.PasswordBg:focus {
    outline: none;
}

.ViaSMS_Image {
    float: left;
}

.ViaEmail_Text {
    width: 60%;
    float: left;
    text-align: left;
}

.ViaEmail_Text p {
    margin: 0px;
    color: #748b94;
    font-size: 0.875rem;
}

.ViaSMS_Image span {
    padding-left: 10px;
    font-weight: normal;
    text-align: left;
    color: #748b94; 
}

.ViaEmail_Text {
    margin: 0px;
    color: #748b94;
    font-size: 1.125rem;
    font-weight: 600;
}

.ViaEmail_Text span {
    margin: 0px;
    color: #748b94;
    font-size: 1.125rem;
    font-weight: 600;
}

.PasswordReset {
    font-size: 1.375rem;
    font-weight: bold;
    color: #182e37;
}

.SuccessfulPasswordDiv {
    text-align: center;
}

.Line {
    width: 72px;
    height: 0.25rem;
    background-color: #a4c344;
    display: inline-block;
    margin-right: 6px;
}

.Line1 {
    width: 72px;
    height: 0.25rem;
    background-color: #e8ebed;
    display: inline-block;
    margin-right: 6px;
}

.CheckBox {
    margin-right: 5px;
}

.untick {
    width: 18px;
}

.BgInputPassword {
    width: 25.75rem;
    height: 2.5rem;
    margin: 0.438rem 0 0;
    border-radius: 8px;
    background-color: #f0f3f5;
}

.PasswordBg {
    width: 370px;
    height: 2.5rem;
    opacity: 0.66;
    background-color: #f0f3f5;
    border: none;
}

.signUpLeftSide {
    float: left;
    width: 51%;
    background-color: #f0f3f5;
    height: 100vh;
    /*JJ UI Doubt */
    vertical-align: middle;
    display: grid;
}

.signUpRightSide {
    float: right;
    width: 49%;
    background-color: #fff;
    height: 100vh;
    display: inline-grid;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
}

.signUpmainLogo {
    margin: 1.85rem 29.246rem 1.583rem 2.74rem;
}

.signUpFormMain {
    width: 100%;
    display: inline-block;
    text-align: center;
}

.signUpFormCard {
    width: 30.5rem;
    height: 39.125rem;
    /*margin: 1.583rem 0 0 6.133rem;*/
    margin: 1.583rem 0 0 0;
    padding: 1.313rem 2.25rem 2.813rem 2.313rem;
    border-radius: 15.5px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    display: inline-block;
}

.signUpRightSide img {
    width: 100%;
}

.signUpHeading {
    margin: 0 0 0.875rem 0.063rem;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.375rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #182e37;
}

/* Login pg, input field css start */

.inputMainContainer {
    float: left;
    margin: 15px 0px 15px 0px !important;
    height: 40px;
    width: 100%;
}

.inputTypeLabelContainer {
    position: relative;
    height: 39px;
    border-radius: 7px;
    border: 1px solid #ccc;
}
/* .inputTypeLabelContainer label {
    position: absolute; 
    font-size: 14px;
    font-weight: normal;
    color: #5f6368;
    background-color: #fff;
    padding: 0px 4px;
    top: -10px;
    left: 8px;
    margin: 0px !important;
    line-height: 16px;
} */
.inputTypeLabelContainer .formFieldLabels {
        position: absolute; 
        font-size: 14px;
        font-weight: normal;
        color: #5f6368;
        background-color: #fff;
        padding: 0px 4px;
        top: -10px;
        left: 8px;
        margin: 0px !important;
        line-height: 16px;
}
.signTypeCheckboxLabels {
    width: 100%;
    float: left;
    display: flex;
}

.inputTypeLabelContainer:focus-within {
    outline: none !important;
    box-shadow: 0px 0px 4px #007bff;
    border:1px solid #007bff;
}
.inputTypePassword {
    border: 0px !important;
    background: none !important;
    font-size: 16px;
    line-height: 20px;
    color: #202124;
    margin: 10px 35px 10px 10px !important;
    width: calc(100% - 55px);
}

.inputType:focus, .inputTypePassword:focus {
    outline: none !important;
    box-shadow: none !important;
    border: 0px !important;
}

/* .inputType::placeholder, .inputTypePassword::placeholder {
  font-family: Arial;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  text-align: left;
  color: #c5c5c5;
} */

.inputErrorLabel {
    color: red;
    font-family: sans-serif;
    font-size: 13px;
    padding: 0px !important;
    margin: 0px 0px 0px 3px !important;
    float: left;
}

/* passwordIcon  */
.EyeIconDiv {
    position: absolute;
    right: 15px;
    bottom: 8px;
}


.w25dot75rem {
    /* width: 25.75rem !important; */
    width: 100%;
}

.w24rem {
    width: 24rem !important;
}

.mt20 {
    margin-top: 20px !important;
}

.mt30 {
    margin-top: 30px !important;
}

.mt35 {
    margin-top: 35px !important;
}

.h60 {
    height: 60px !important;
}
.pl40 {
    padding-left: 40px;
}

.invalid {
    border: 1px solid red;
}

.ResetPasswordButton {
    width: 100%;
    /* height: 2.875rem; */
    /*margin: 1.4rem 0 0;*/
    padding: 0.6rem 0rem 0.6rem;
    border-radius: 8px;
    /* box-shadow: 0 12px 16px 0 rgba(44, 121, 152, 0.3); */
    /* background-image: linear-gradient(237deg, #0f688b 61%, #1a5974); */
    text-align: center;
    margin-top: 7px;
    background-color: #0b70d1;
}

.LabelText {
    font-size: 0.875rem;
    font-weight: 600;
    color: #8a8a8a;
    margin-bottom: 2px;
    margin-top: 25px;
}

a:link {
    text-decoration: underline;
}

.ForgotPassword {
    height: 16px;
    float: right;
    margin-top: 1rem;
    font-family: Arial;
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #1a73e8;
}

.Sign-In {
    width: 44px;
    height: 16px;
    font-family: Arial;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}
/* Login pg input field css end */

/* reset password start */
.resetpassEmailText {
    color: #748b94;
    font-size: .875rem;
    font-weight: 400;
    margin-top: 15px;
    text-align: left;
}
.closemarkErrorText {
    width: 400px;
    /* height: 34px; */
    margin: 0 0 0 9.8px;
    font-family: Arial;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #3c4043;
}
/* reset password end */

 /*----start style Popover in change password page------*/
 .popoverP .fa {
    margin-right: 10px;
}

.Valid {
    color: #3a7d34;
}

.Invalid {
    color: #E41E26;
    font-size: 13px;
    font-family: Segoe UI;
    margin-bottom: 10px;
}
/*----end style Popover in change password page------*/
/*progress start*/
.stepwizard-step p {
    margin-top: 10px;
    position: relative;
    top: -61px;
    margin-bottom: 5px;
}

.stepwizard-row {
    display: table-row;
}

.stepwizard {
    display: table;
    width: 100%;
    position: relative;
}

.stepwizard-row:before {
    top: 12px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: #eaeaec;
}

.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}

.signUpProgress .btn-circle {
    width: 1.375rem;
    height: 1.375rem;
    border-radius: 50%;
    text-align: center;
    padding: 3px 0;
    font-size: 12px;
    line-height: 1;
    border-radius: 15px;
}

.signUpProgress .btn_defaultProgress {
    background: #e8ebed;
    border-color: #e8ebed;
}

.signUpProgress {
    margin-top: 45px;
}

.signUpProgress .progressGreenText {
    color: #0c8872;
}

.signUpProgress .progressGreen {
    color: #fff;
    background-color: #0c8872;
    border-color: #0c8872;
}
/*progress end*/

.signUpForm {
    position: relative;
    top: -22px;
    text-align: left;
}

.signUpForm .form-group {
    margin-bottom: 10px;
    text-align: left;
}

.signUpForm .formField {
    border-radius: 8px;
    background-color: #f0f3f5;
    border-color: transparent;
    height: calc(1.5em + .75rem + 4px);
}

.signUpForm .formField:focus,
.signUpForm .formField:active {
    border-color: #0f688b;
    outline: 0;
    box-shadow: 0 0 0 0.01rem #0f688b;
}

.signUpBtn {
    width: 100%;
    height: 2.875rem;
    margin: 1.4rem 0 0;
    padding: 0.6rem 0rem 0.6rem;
    border-radius: 23px;
    box-shadow: 0 12px 16px 0 rgba(44, 121, 152, 0.3);
    background-image: linear-gradient(237deg, #0f688b 61%, #1a5974);
    text-align: center;
    color: #fff;
    border: none;
}

.signUpForm .signUpBtn:hover,
.signUpForm .signUpBtn:focus,
.signUpForm .signUpBtn:active,
.signUpForm .signUpBtn:active:focus {
    outline: 0;
    border: 0;
    box-shadow: 0 12px 16px 0 rgba(44, 121, 152, 0.3) !important;
}

.signUpForm label {
    margin-bottom: .1rem;
    font-size: 0.875rem;
}

.ConfirmIdentity {
    font-size: 1.125rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #182e37;
    margin: 0.5rem 0 0.375rem 0.063rem;
}

.confirmDescp {
    margin: 0.375rem 2.625rem 1.375rem 0.063rem;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #748b94;
}

.verificationcodeText {
    margin: 1.375rem 0rem 0rem 0.063rem;
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #748b94;
}
.sendCodeBtn {
    margin: 0.2rem 3.406rem 0.8rem 0.094rem;
    padding: 0.844rem 1.719rem;
    border-radius: 23px;
    border: solid 2px #006994;
    background-color: #fff;
    font-weight: 600;
    font-weight: 800;
    color: #006994;
}

.sendCodeBtn:hover,
.sendCodeBtn:active,
.sendCodeBtn:focus {
    color: #006994;
    border: solid 2px #006994;
    outline: 0;
    box-shadow: none;
}
.congratulationText {
    margin: 0.563rem 7.688rem 0.938rem 7.75rem;
    font-size: 1.375rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #182e37;
}

.successfulText {
    margin: 0.938rem 1.063rem 1.688rem 1.125rem;
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: center;
    color: #748b94;
}
.formField {
    border-radius: 8px;
    background-color: #f0f3f5;
    border-color: transparent;
    height: calc(1.5em + .75rem + 4px);
    font-size: 0.75rem;
}

.formField:focus,
.formField:active {
    border-color: #0f688b;
    outline: 0;
    box-shadow: 0 0 0 0.01rem #0f688b;
    background-color: #f0f3f5;
}
/* Sign in and forget password css ends */

/* Sidebar start */
.menu-list {
    width: 100%;
    height: calc(100vh - 135px);
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 11px;
} 
.sidebarLabel {
    margin:0 0 0 1.6rem !important;
    font-size: 0.938rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: left;
    /* color: #7a7c8a; */
    color: #a4a6b5;
    display: inline-block;
    margin-bottom: 0px;
    line-height: 16px;
    cursor: pointer;
    padding: 10px 0px;
}
.sidebarActive {
    border-radius: 13px;
    background-image: linear-gradient(244deg, #4ca0c2 100%, #027aaa -5%);
}
.sidebarActive .activeText {
    font-size: 0.938rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.1px;
    text-align: left;
    color: #fff;
    display: inline-block;
    width: 115px;
    margin-bottom: 0px;
    line-height: 25px;
}

.sidebar i {
    color: #35697e;
}

.sidebar .collapse li {
    line-height: 7px;
}

.collapse-menu {
    transition: all 3s ease-out;
}
/* Sidebar ends */
/*custom radio start*/

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="radio"]:checked+label,
[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 5px;
    cursor: pointer;
    line-height: 15px;
    display: inline-block;
    color: #666;
}

/* [type="radio"]:checked+label:before,
[type="radio"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 0.875rem;
    height: 0.875rem;
    border: 1px solid #4ca0c2;
    border-radius: 100%;
    background: #fff;
} */

/* [type="radio"]:checked+label:after,
[type="radio"]:not(:checked)+label:after {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    background: #4ca0c2;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
} */

[type="radio"]:not(:checked)+label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

[type="radio"]:checked+label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
/*custom radio end*/

/* Common css start */
.modal-open .modal {
    display: flex !important;
    z-index: 99998;
}

.modal.show .modal-dialog {
    margin: auto;
}
.mainContent {
    width: calc(100% - 190px);
    overflow-x: hidden;
    overflow-y: hidden;
    margin-left: 190px;
    height: calc(100vh - 71px);
}
.collapseMainContent {
   /*  width: calc(100vw - 0px); */
   width: 100%;
    margin-left: 0px;
}

.hide {
    opacity: 0;
    height: 0 !important;
}

button:focus,
button:hover,
button:active {
    outline: 0;
}

.dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 16px;
}

.dropdown-menu {
    min-width: 8rem !important;
    transform: translate(-50px, 30px) !important;
}
.checkbox_custom {
    display: block;
}

.checkbox_custom {
    content: '';
    width: 20px;
    height: 18px;
    /* background-color: #4283bd; */
    border-radius: 5px;
    border: 2px solid #707070;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    margin: 10px 5px 10px 10px;
}

.checkbox_custom:checked {
    content: "";
    padding: 2px;
    width: 20px;
    height: 18px;
    /* background-color: #4283bd !important; */
    /* border: 2px solid #1a5974; */
    border-width: 0 3px 3px 0;
    transform: rotate(359deg);
}

input[type=checkbox]:checked+span {
    color: #4283bd !important;
}

.SelectTextP {
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #0f6689; */
    color: #49bcf8;
}

.cameraCTA-Container .UnSelectTextP {
    color: #8a8a8a;
}

.UnSelectTextP {
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    /* color: #8a8a8a; */
    color: #FFFF;
}

.PageOfLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* .panel-collapse {
    max-height: 500px;
    overflow: hidden;
    transition: max-height .25s ease;
} */

/* .panel-close {
    max-height: 0;
} */
.tooltip {
    display: inline;
    position: relative;
}

.tooltip:hover {
    color: #c00;
    text-decoration: none;
}
.custom-input {
    border-bottom: 1px solid #0179a9;
}

.custom-input input {
    width: 80%;
    border: none;
    background-color: transparent;
    height: 2rem;
    word-break: break-word;
    word-wrap: break-word;
}

a:hover {
    cursor: pointer;
}

/* .rs-picker-toggle-wrapper {
    width: 360px;
} */

.ant-picker-range {
    height: 38px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #00000014;
    border: 0.5px solid #A6C0C9;
    border-radius: 8px;
    margin-left: 5px;
}

.LocationNameText {
    font-size: 0.8rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #8a8a8a;
    margin-top: 0px;
}

.LocationNameText span {
    font-size: 0.8rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4e4e4e;
    margin-left: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
.btn:focus {
    outline: 0 !important;
    box-shadow: unset !important;
}

p+p {
    margin-top: 0px !important;
}

progress {
    -webkit-appearance: none;
    appearance: none;
}

progress::-webkit-progress-bar {
    background-color: #000;
    border-radius: 2rem;
    height: 0.4em;
}

progress::-webkit-progress-value{
    background-color: #FFFFFF;
    border-radius: 1.9rem;
    height: 0.3em;
}

.btn-outline-primary {
    border-color: #0b70d1 !important;
}
.btn-outline-primary:hover {
    background-color: #0b70d1  !important;
    border-color: #0b70d1  !important;
    color: #fff !important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active, 
.btn-outline-primary:not(:disabled):not(.disabled):active, 
.show>.btn-outline-primary.dropdown-toggle {
    background-color: #0b70d1 !important;
    border-color: #0b70d1 !important;
    color: #fff !important;
}



/* .table-fixed tbody tr:hover td {
    background-color: #f2f6fe !important;
} */


/* .tbody-dashboard tr:hover td {
    background-color: #f2f6fe !important;
} */
select, input.inputType:disabled {
    opacity: 0.7;
}
.inputType__control input:disabled {
    opacity: 0.7;
}


/* Common css ends */

/* Workflow css start */
.actionText {
    margin-bottom: 0;
}

.actionText span {
    display: block;
}

.actionText span:first-child {
    font-size: 0.938rem;
    font-weight: 600;
    color: #182e37;
}

.actionText span:last-child {
    font-size: 0.75rem;
    font-weight: normal;
    color: #748b94;
}

.NextMoveButton.addWorkFlow-saveWorkFlow-btn {
    margin-top: 3px !important;
}

.AddMore.workflowPage {
    margin-top: 0.75rem !important;
}


.mainContentWithoutSB {
    width: 100%;
    margin-top: 7px;
}

.FlowCharts {
    width: 59%;
    height: calc(100vh - 110px);
    display: inline-block;
}

.Configure {
    width: 20%;
    display: inline-block;
}

.MobileView {
    width: 21%;
    display: inline-block;
}



.MobileContent {
    height: calc(100vh - 160px);
    margin: 20px 20px 20px 10px;
    border-radius: 12px;
    background-color: #c9d4d8;
}

.SimpleDropdown {
    width: auto;
    height: 2.375rem;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
    border: solid 1px #bcbdbe;
    background-color: #fff;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: space-between;
}

.SimpleDropdown p {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #bcbdbe;
    display: inline-block;
    margin-bottom: 0px;
}



.ActionBgDiv {
    height: 12.563rem;
    padding: 1.375rem 0.938rem 1.313rem 0.875rem;
    border-radius: 12px;
    box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
    background-color: #fff;
    margin-top: 15px;
}




.AccordionActionBgDiv {
    height: calc(100vh - 235px);
    padding: 1.375rem 0.938rem 1.313rem 0.875rem;
    border-radius: 12px;
    box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
    background-color: #fff;
    margin-top: 15px;
    overflow: auto;
}

/* .accordion .card-header:after {
    font-family: 'FontAwesome';
    content: "\f0d8";
    float: right;
    color: #027aaa;
} */

.accordion .card-header.collapsed:after {
    /* symbol for "collapsed" panels */
    content: "\f0d7";
    color: #027aaa;
}

.card {
    border: none;
    padding: unset;
}

.AccordionActionCardHeader {
    background: unset;
    padding: unset !important;
    padding-bottom: 10px !important;
}

.FirstAccordionBgDiv {
    height: auto;
    padding: 1.375rem 0.938rem 1.313rem 0.875rem;
    border-radius: 12px;
    box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
    background-color: #fff;
    margin-top: 15px;
}


.Select_Action {
    background-image: linear-gradient(45deg, transparent 50%, #006994 60%), linear-gradient(135deg, #006994 40%, transparent 50%) !important;
    background-position: calc(100% - 30px) 14px, calc(100% - 20px) 14px, 100% 0;
    background-size: 5px 7px, 11px 5px;
    background-repeat: no-repeat;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #f0f3f5;
    border: none;
    font-size: 0.875rem;
    height: 2.5rem;
}

.Select_Action:focus {
    color: #495057;
    background-color: #fff;
    border: none;
    outline: 0;
    box-shadow: none;
    background-color: #f0f3f5;
}

.CheckboxDiv {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
}

.ActionTimeDiv {
    height: 130px;
    padding: 1.375rem 0.938rem 1.313rem 0.875rem;
    border-radius: 12px;
    box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
    background-color: #fff;
    margin-top: 15px;
}

.ActionTimeText {
    opacity: 0.42;
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.IfNotText {
    font-size: 0.875rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #bcbdbe;
    margin-bottom: 0px;
}

.NumberView {
    width: 38%;
    margin-right: 10px;
    display: inline-block;
}

.MinsText {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
}

.EscalationAccordionBgDiv {
    height: auto;
    padding: 1.375rem 0.938rem 1.313rem 0.875rem;
    border-radius: 12px;
    box-shadow: 0 2px 27px 0 rgb(0 0 0 / 6%);
    background-color: #fff;
    margin-top: 15px;
}




.ActionTimeText1 {
    font-size: 0.875rem;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}


/*custom square checkbox start*/

.Custom_checkbox_Square {
    display: none;
}

.Custom_checkbox_Square_label {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    font-size: 0.875rem;
    color: #6c6c6c;
}

.Custom_checkbox_Square+.Custom_checkbox_Square_label:before {
    content: '';
    background: #fff;
    border-radius: 3px;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    margin-right: 10px;
    width: 1rem;
    height: 1rem;
    margin: 0.125rem 0.486rem 0.063rem 0;
    border: solid 2px #0f688b;
}

.Custom_checkbox_Square:checked+.Custom_checkbox_Square_label:after {
    content: "";
    padding: 2px;
    position: absolute;
    width: 1px;
    height: 9px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    top: 6px;
    left: 6px;
}

.SimpleDropdown .Custom_checkbox_Square:checked+.Custom_checkbox_Square_label:before {
    background-color: #0f688b;
    border: solid 1px #0f688b;
}


/*custom square checkbox end*/



.wf-icon {
    height: 4.875rem;
    margin: 2.781rem 0.563rem 0.813rem 19.688rem;
}

.start-wf-btn {
    background-color: #fff;
    margin: 2.781rem 0.563rem 0.813rem 19.688rem;
    color: #0f688b;
    border-radius: 25px;
    border: solid 1px #0f688b;
}

.wf-card {
    border: none;
}

.dndflow {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: 1rem;
    overflow-x: none;
    scroll-behavior: smooth;
    /* border: 1px dashed #000; */
}

.dndflow .reactflow-wrapper {
    flex-grow: 1;
}

.dndflow .reactflow-wrapper .react-flow__node-default {
    width: 180px !important;
}

.dndflow .dndnode {
    align-items: center;
    border: 1px solid #1a192b;
    border-radius: 2px;
    cursor: grab;
    display: flex;
    height: 20px;
    justify-content: center;
    padding: 1rem;
}

@media screen and (min-width: 768px) {
    .dndflow {
        flex-direction: row;
    }
}

.dndflow .dndnode,
.dndflow aside .description {
    margin-bottom: 10px;
}

.dndflow .dndnode.input {
    border-color: #0041d0;
}

.dndflow .dndnode.output {
    border-color: #ff0072;
}

.data-space {
    height: 100vh;
    scroll-behavior: smooth;
    overflow-y: scroll;
}

.placeholder div {
    background: #eee;
    height: 10px;
    margin-bottom: 4px;
    width: 100%;
}

.placeholder div:last-child {
    margin-bottom: 0;
}

.react-flow__node-zoom {
    background: #fff;
    border: 1px solid #555;
    border-radius: 5px;
    font-size: 12px;
    line-height: 1.2;
    padding: 10px;
    text-align: center;
    width: 150px;
}

.user-type:hover {
    cursor: pointer;
    box-shadow: 0 0 11px rgba(10, 10, 10, .2);
}




.add-workflow-card {
    height: 80%;
    background-color: #fff;
    border-radius: 12px;
}

.wf-name {
    width: 12.875rem;
    height: 2.375rem;
    margin: 0rem 1rem 0rem 1rem;
    padding: 0.688rem 0.628rem 0.688rem 0.608rem;
    border-radius: 8px;
    border: solid 1px #bcbdbe;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a595f;
}

.save-wf {
    width: 10rem !important;
    height: 2.375rem;
    margin: 0 0.813rem;
    padding: 0.688rem 0.955rem 0.688rem 0.938rem;
    border-radius: 8px;
    border: solid 1px #bcbdbe;
    background-color: #fff;
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #4a595f;
}



/* Workflow css end */

/*custom checkbox start*/
.checkbox-custom {
    display: none;
}
.checkbox-custom-label {
    display: inline-block;
    position: relative;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
    color: #182e37;
    font-size: 0.875rem;
}
.checkbox-custom+.checkbox-custom-label:before {
    content: '';
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    margin-right: 10px;
    width: 1rem;
    height: 1rem;
    margin: 0.125rem 0.486rem 0.063rem 0;
    border: solid 1px #abb9be;
}
.checkbox-custom:checked+.checkbox-custom-label:after {
    content: "";
    padding: 2px;
    position: absolute;
    width: 1px;
    height: 9px;
    border: solid white;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    top: 6px;
    left: 6px;
}
.checkbox-custom:checked+.checkbox-custom-label:before {
    background-color: #0f688b;
    border: solid 1px #0f688b;
}
.filterModalDialog {
    margin-right: 0;
    position: relative;
    top: 202px;
    right: 21px;
}
/*custom checkbox end*/

/*  Custom Slider start css */

.slider {
    -webkit-appearance: none;
    width: 70%;
    height: 8px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    border-radius: 10px;
    -webkit-transition: .2s;
    transition: opacity .2s;
    display: inline-block;
}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #027aaa;
    cursor: pointer;
    border-radius: 10px;
    border: 5px solid #fff;
    box-shadow: 1px 2px 3px 1px rgba(76, 80, 82, 0.3);
}

.slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #027aaa;
    cursor: pointer;
    border-radius: 10px;
}

/*  Custom Slider end css */


/* Chatflow css start */
/* .chatFlowMobileHeader {
    position: relative;
    height: 122px;
} */
/* .chatFlowMobileHeaderBottom {
    position: absolute;
    top: 46px;
    border-radius: 0px 0px 12px 12px;
    background-color: #0D70D1;
    height: 76px;
    width: 100%;
    z-index: 0;
} */

/* .chatFlowClockDiv {
    background-color: #f4f3f2;
    border-radius: 25px;
    width: 60px;
    float: left;
    margin: 2px 0px 0px 86px;
} */

/* .chatFlowClockClass {
    float: left;
    margin: 1px 4px 1px 1px;
} */

/* .chatFlowMobileAlertTime {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
    padding: 0px !important;
} */

/* .chatFlowMobileBtmOne {
    margin: 25px 15px 0px 15px;
    float: left;
} */

/* .chatFlowMobileAlertBtoomText {
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    float: left;
    margin: 0px 15px 0px 0px !important;
    padding: 0px !important;
} */

/* .chatFlowMobileDetailText {
    color: #ffffff;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px !important;
} */

.chatFlowTimeIconContainer {
    background-color: #ffffff;
    border-radius: 25px;
    height: 22px;
    width: 22px;
    float: left;
}

.chatFlowChatTimeIcon {
    float: left;
    padding: 0px !important;
    margin: 2px 0px 0px 2px;
}

.chatFlowChatTimeIconText {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 4px 0px 0px 5px !important;
    padding: 0px !important;
}

.chatFlowChatBox {
    background-color: #ffffff;
    width: calc(100% - 30px);
    border-radius: 0px 10px 10px 10px;
    float: left;
    margin: 0px 0px 0px 0px;
}
.chatFlowChatText {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 10px !important;
    padding: 0px !important;
}

.chatFlowChatText span {
    font-weight: bold;
    color: #000000;
}

/* .chatFlowConfirmationBox {
    border-radius: 8px;
    border: solid 1px #dadada;
    float: left;
    width:170px;
} */

.chatFlowConfirmationBoxTitle {
    background-color: #027aaa;
    border-radius: 7px 7px 0px 0px;
    height: 40px;
}

.chatFlowConfirmationBoxTitle p {
    color: #ffffff;
    font-size: 11px;
    float: left;
    margin: 12px 15px 12px 15px !important;
}



.chatFlowConfirmationBoxButtonFull {
    background-color: #ffffff;
    border-radius: 0px 0px 7px 7px;
    height: 35px;
    float: left;
    min-width: 50px;
}


.chatFlowConfirmationBoxButtonCenter {
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    height: 35px;
    float: left;
    min-width: 50px;
}

/* .chatFlowConfirmationBoxButtonSep {
    border-color: #dadada;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    cursor: pointer;
} */

.chatFlowConfirmationBoxButtonRight {
    background-color: #ffffff;
    border-radius: 0px 0px 7px 0px;
    height: 35px;
    float: left;
    min-width: 50px;
}

.chatFlowBoxNewDateText {
    color: #767676;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
}

.chatFlowBoxCenterText {
    float: left;
    background: #ffffff;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
}

.chatFlowBoxCenterText p {
    color: #767676;
    font-size: 11px;
    float: left;
    margin: 10px 15px !important;
}
.chatFlowWebHeaderAlertText {
    float: left;
    color: #182e37;
    font-size: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.chatFlowWebHeaderSeverityBox {
    border-radius: 24px;
    background-color: #ff0000;
    float: left;
    margin: 0px 0px 0px 25px;
}

.chatFlowWebHeaderSeverityBox p {
    float: left;
    color: #ffffff;
    font-size: 12px;
    padding: 0px !important;
    margin: 3px 20px !important;
}

.chatFlowWebOperatorNameText {
    float: left;
    color: #8a8a8a;
    font-weight: bold;
    font-size: 12px;
    padding: 0px !important;
    margin: 0px 0px 0px 25px !important;
}

.chatFlowWebHeadTime {
    border-radius: 25px;
    height: 28px;
    background-color: #f4f3f2;
    border: 1px solid #c4d9e1;
}

.chatFlowWebHeadTime img {
    float: left;
    margin: 1px 7px 1px 1px;
}

.chatFlowWebHeadTime p {
    float: left;
    color: #131415;
    font-size: 16px;
    padding: 0px !important;
    margin: 2px 0px 0px 0px !important;
}

/* .chatFlowWebHeaderBottom {
    background-color: #0179a9;
    height: 36px;
} */

/* .chatFlowWebViewWorkflow {
    border-radius: 25px;
    height: 24px;
    border: 1px solid #ffffff;
    cursor: pointer;
}

.chatFlowWebViewWorkflow p {
    float: left;
    color: #ffffff;
    font-size: 13px;
    padding: 0px !important;
    margin: 0px 0px 0px 0px !important;
} */

.chatFlowWebHeaderAddress {
    float: left;
    color: #ffffff;
    font-size: 14px;
    padding: 0px !important;
    margin: 2px 0px 0px 0px !important;
}

/* .chatFlowTimeIconContainerBig {
    background-color: #ffffff;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    float: left;
} */

/* .chatFlowChatTimeIconBig {
    float: left;
    padding: 0px !important;
    margin: 1px 0px 0px 1px;
    height: 22px;
} */

/* .chatFlowChatTimeIconTextBig {
    color: #828c90;
    font-size: 12px;
    float: left;
    margin: 0 1rem !important;
    padding: 0px !important;
} */
.chatFlowRemainingTimeTextBig {
    color: #828c90;
    font-size: 12px;
    float: left;
    margin: 2px 10px 0px 0px !important;
    padding: 0px !important;
}

.chatFlowBoxNewDateTextBig {
    color: #767676;
    font-size: 12px;
    float: left;
    margin: 0px 0px 0px 0px !important;
}

.chatFlowBoxCenterTextBig {
    float: left;
    background: #ffffff;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
}

.chatFlowBoxCenterTextBig p {
    color: #767676;
    font-size: 14px;
    float: left;
    margin: 10px 15px !important;
}

/* .chatFlowBoxCenterTextBigText12 {
    font-size: 12px !important;
} */

.chatFlowWebChatBox {
    float: left;
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
}

/* .chatFlowChatTextBig span {
    font-weight: bold;
    color: #000000;
} */

.chatFlowBorder {
    border: solid 4px #182e37;
    border-radius: 24px;
    margin: 0px 35px;
    height: calc(100% - 122px);
    background-color: #f0f3f5;
}

.chatFlowChatProfileIconTextBig {
    color: #828c90;
    font-size: 12px;
    float: left;
    margin: 5px 7px 0px 0px !important;
    padding: 0px !important;
}

.chatFlowProfileIconContainerBig {
    background-color: #ffffff;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    float: left;
}

.chatFlowChatProfileIconBig {
    float: left;
    padding: 0px !important;
    margin: 1px 0px 0px 1px;
    height: 22px;
}

.chatFlowChatBoxRight {
    background-color: #ffffff;
    border-radius: 10px 0px 10px 10px;
    float: left;
    margin: 0px 0px 0px 0px;
}

/* .chatFlowMultiImageContainer {
    float: left;
}

.chatFlowMultiImageContainer img {
    float: left;
    height: 227px;
    width: 200px;
    border-radius: 0px 10px 10px 10px;
} */

.chatFlowMultiVideoContainer {
    float: left;
    position: relative;
    margin: 0px 10px 0px 0px;
    display: block;
    width: 127px;
    height: 172px;
}

.chatFlowMultiVideoThumb {
    position: absolute;
}

.chatFlowCommentBox {
    border-radius: 8px;
    border: solid 1px #dadada;
    float: left;
    min-width: 580px;
    background-color: #ffffff;
}

.chatFlowCommentBoxTitle {
    background-color: #027aaa;
    border-radius: 7px 7px 0px 0px;
    height: 40px;
}

.chatFlowCommentBoxTitle p {
    color: #ffffff;
    font-size: 14px;
    float: left;
    margin: 10px 15px 9px 15px !important;
}

.chatFlowCommentBoxTextBoxContainer {
    height: 100px;
}

.chatFlowCommentBoxTextBox {
    margin: 5px;
    font-size: 14px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    max-height: calc(100% - 10px);
    resize: none;
    border: none;
}

/* .chatFlowVoiceBoxContainer {
    padding: 5px 10px;
    float: left;
    background-color: #027aaa;
    margin: 0px 15px 0px 0px;
    border-radius: 6px;
} */

.chatFlowVoiceAttacmentButton {
    background-color: #027aaa;
    border-radius: 25px;
    height: 35px;
    width: 35px;
}

.chatFlowVoicePlayPause {
    float: left;
}

.chatFlowVoiceSpectrogram {
    float: left;
    margin: 0px 0px 0px 10px;
}
.chatFlowMobileHeaderTopContent {
    margin: 20px 15px;
    float: left;
}

/* .chatFlowMobileHeaderBottom {
    position: absolute;
    top: 46px;
    border-radius: 0px 0px 12px 12px;
    background-color: #0D70D1;
    height: 76px;
    width: 100%;
    z-index: 0;
} */

.chatFlowMobileAlertTypeArrow {
    float: left;
    margin: 3px 15px 0px 0px;
}

/* .chatFlowMobileAlertTypeText {
    color: #182e37;
    font-weight: bold;
    font-size: 12px;
    float: left;
    margin: 0px 15px 0px 0px !important;
    padding: 0px !important;
} */

.chatFlowMobileAlertTypeText2 {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 1px 15px 0px 0px !important;
    padding: 0px !important;
}

/* .chatFlowClockDiv {
    background-color: #f4f3f2;
    border-radius: 25px;
    width: 60px;
    float: left;
    margin: 2px 0px 0px 86px;
} */

/* .chatFlowClockClass {
    float: left;
    margin: 1px 4px 1px 1px;
} */

/* .chatFlowMobileAlertTime {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
    padding: 0px !important;
} */

/* 
.chatFlowMobileAlertBtoomText {
    color: #ffffff;
    font-weight: bold;
    font-size: 12px;
    float: left;
    margin: 0px 15px 0px 0px !important;
    padding: 0px !important;
} */

.chatFlowMobileBottomSeverity {
    background-color: #f4f3f2;
    height: 16px;
    border-radius: 4px;
    float: left;
}

.chatFlowMobileSeverityText {
    color: #7e7e7d;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 5px !important;
}

/* .chatFlowMobileDetailText {
    color: #ffffff;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
    padding: 0px 0px !important;
} */

.chatFlowChats {
    margin: 15px;
    float: left;
    width: calc(100% - 30px);
    height: calc(100% - 150px);
    overflow-y: auto;
}

/* .chatFlowBoxContainer {
    width: 100%;
    margin: 5px 0px 0px 0px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
} */

/* .chatFlowBoxContainer-bot {
    width: 100%;
    margin: 5px 0px 0px 0px;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
} */

.chatFlowTimeIconContainer {
    background-color: #ffffff;
    border-radius: 25px;
    height: 22px;
    width: 22px;
    float: left;
}

.chatFlowChatTimeIcon {
    float: left;
    padding: 0px !important;
    margin: 2px 0px 0px 2px;
}

.chatFlowChatTimeIconText {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 4px 0px 0px 5px !important;
    padding: 0px !important;
}

.chatFlowChatBox {
    background-color: #ffffff;
    width: calc(100% - 30px);
    border-radius: 0px 10px 10px 10px;
    float: left;
    margin: 0px 0px 0px 0px;
}

.chatFlowChatText {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 10px !important;
    padding: 0px !important;
}

.chatFlowChatText span {
    font-weight: bold;
    color: #000000;
}

.chatFlowTimeRemainingContainer {
    float: left;
    background: #ffffff;
    border-radius: 25px;
    margin: 0px 0px 0px 0px;
}

.chatFlowRemainingClock {
    float: left;
    margin: 5px 5px 5px 5px;
}

.chatFlowRemainingTimeText {
    color: #828c90;
    font-size: 11px;
    float: left;
    margin: 3px 10px 0px 0px !important;
    padding: 0px !important;
}

.chatFlowConfirmationBox {
    border-radius: 8px;
    border: solid 1px #dadada;
    float: left;
    /*width:170px;*/
}

.chatFlowConfirmationBoxTitle {
    background-color: #027aaa;
    border-radius: 7px 7px 0px 0px;
    height: 40px;
}

.chatFlowConfirmationBoxTitle p {
    color: #ffffff;
    font-size: 11px;
    float: left;
    margin: 12px 15px 12px 15px !important;
}

.chatFlowConfirmationBoxButtonFull {
    background-color: #ffffff;
    border-radius: 0px 0px 7px 7px;
    height: 35px;
    float: left;
    min-width: 50px;
}


.chatFlowConfirmationBoxButtonCenter {
    background-color: #ffffff;
    border-radius: 0px 0px 0px 0px;
    height: 35px;
    float: left;
    min-width: 50px;
}

/* .chatFlowConfirmationBoxButtonSep {
    border-color: #dadada;
    border-style: solid;
    border-width: 0px 1px 0px 0px;
} */

.chatFlowConfirmationBoxButtonRight {
    background-color: #ffffff;
    border-radius: 0px 0px 7px 0px;
    height: 35px;
    float: left;
    min-width: 50px;
}
.chatFlowBoxNewDateText {
    color: #767676;
    font-size: 11px;
    float: left;
    margin: 0px 0px 0px 0px !important;
}
.chatFlowBoxCenterText {
    float: left;
    background: #ffffff;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
}
.chatFlowBoxCenterText p {
    color: #767676;
    font-size: 11px;
    float: left;
    margin: 10px 15px !important;
}

.chatFlowWebMaiContainer {
    /* height: calc(100vh - 138px);
    height: calc(100vh - 103px); */
    overflow-y: auto;
    overflow-x: hidden;
}

.chatFlowWebHeaderTop {
    height: 45px;
    background-color: #ffffff;
    border-radius: 10px 10px 0px 0px;
    margin: 8px 0px 0px 0px;
}

.chatFlowWebHeaderAlertText {
    float: left;
    color: #182e37;
    font-size: 18px;
    padding: 0px !important;
    margin: 0px !important;
}

.chatFlowWebHeaderSeverityBox {
    border-radius: 24px;
    background-color: #ff0000;
    float: left;
    margin: 0px 0px 0px 25px;
}

.chatFlowWebHeaderSeverityBox p {
    float: left;
    color: #ffffff;
    font-size: 12px;
    padding: 0px !important;
    margin: 3px 20px !important;
}

.chatFlowWebOperatorNameText {
    float: left;
    color: #8a8a8a;
    font-weight: bold;
    font-size: 12px;
    padding: 0px !important;
    margin: 0px 0px 0px 25px !important;
}

.chatFlowWebHeadTime {
    border-radius: 25px;
    height: 28px;
    background-color: #f4f3f2;
    border: 1px solid #c4d9e1;
}

.chatFlowWebHeadTime img {
    float: left;
    margin: 1px 7px 1px 1px;
}

.chatFlowWebHeadTime p {
    float: left;
    color: #131415;
    font-size: 16px;
    padding: 0px !important;
    margin: 2px 0px 0px 0px !important;
}

/* .chatFlowWebHeaderBottom {
    background-color: #0f62ac;
    height: 60px;
} */

.view-workflow-btn {
    padding-left: 150px !important;
}

/* .chatFlowWebViewWorkflow {
    border-radius: 25px;
    height: 24px;
    border: 1px solid #000000;
    width: fit-content;
    padding-right: 10px;
    padding-left: 10px;
}

.chatFlowWebViewWorkflow p {
    float: left;
    color: #000000;
    font-size: 13px;
    padding: 0px !important;
    margin: 0px 0px 0px 0px !important;
} */

.chatFlowWebHeaderAddress {
    float: left;
    color: #ffffff;
    font-size: 14px;
    padding: 0px !important;
    margin: 2px 0px 0px 0px !important;
}

/* .chatFlowTimeIconContainerBig {
    background-color: #ffffff;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    float: left;
} */

/* .chatFlowChatTimeIconBig {
    float: left;
    padding: 0px !important;
    margin: 1px 0px 0px 1px;
    height: 22px;
} */


.chatFlowRemainingTimeTextBig {
    color: #828c90;
    font-size: 12px;
    float: left;
    margin: 2px 10px 0px 0px !important;
    padding: 0px !important;
}

/* .chatFlowConfirmationBoxTitleBig {
    background-color: #0f62ac;
    border-radius: 7px 7px 0px 0px;
    height: 60px;
}

.chatFlowConfirmationBoxTitleBig p {
    color: #ffffff;
    font-size: 14px;
    float: left;
    margin: 12px 15px 0 15px !important;
    overflow-wrap: break-word;
    width: 350px;
} */

.chatFlowBoxNewDateTextBig {
    color: #767676;
    font-size: 12px;
    float: left;
    margin: 0px 0px 0px 0px !important;
}

.chatFlowBoxCenterTextBig {
    float: left;
    background: #ffffff;
    border-radius: 10px;
    margin: 0px 0px 0px 0px;
}

.chatFlowBoxCenterTextBig p {
    color: #767676;
    font-size: 14px;
    float: left;
    margin: 10px 15px !important;
}

/* .chatFlowBoxCenterTextBigText12 {
    font-size: 12px !important;
} */

.chatFlowWebChatBox {
    float: left;
    height: 56px;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
}

textarea:focus,
input:focus {
    outline: none;
}

.chatFlowWebChatAttachButton {
    float: left;
    height: 32px;
    width: 130px;
    border-radius: 16px;
    border: solid 1px #027aaa;
    margin: 12px 15px 0px 0px;
}

.chatFlowWebChatAttachButton p {
    color: #0e6689;
    font-size: 14px;
    float: left;
    margin: 5px 15px 5px 20px !important;
}

.chatFlowWebChatAttachButton img {
    float: left;
    margin: 8px 0px 0px 0px;
}

/* .chatFlowWebChatVoiceButton {
    height: 30px;
    width: 45px;
    border-radius: 99px;
    background-color: #0f688b;
    margin: 0 15px 0px 0px;
    padding: 0 1rem;
} */

.chatFlowLiveMap {
    float: left;
    height: 316px;
    width: 288px;
    border-radius: 8px;
    border: solid 1px #707070;
    position: relative;
}

.chatFlowFullScreenContainer {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.chatFlowLiveVideo {
    float: left;
    height: 160px;
    width: 288px;
    border-radius: 8px;
    border: solid 1px #707070;
    position: relative;
}

.chatFlowPlayContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}

.chatFlowChatProfileIconTextBig {
    color: #828c90;
    font-size: 12px;
    float: left;
    margin: 5px 7px 0px 0px !important;
    padding: 0px !important;
}

.chatFlowProfileIconContainerBig {
    background-color: #ffffff;
    border-radius: 25px;
    height: 25px;
    width: 25px;
    float: left;
}

.chatFlowChatProfileIconBig {
    float: left;
    padding: 0px !important;
    margin: 1px 0px 0px 1px;
    height: 22px;
}

.chatFlowChatBoxRight {
    background-color: #ffffff;
    border-radius: 10px 0px 10px 10px;
    float: left;
    margin: 0px 0px 0px 0px;
}

/* .chatFlowMultiImageContainer {
    float: left;
} */

.chatFlowMultiVideoContainer {
    float: left;
    position: relative;
    margin: 0px 10px 0px 0px;
    display: block;
    width: 127px;
    height: 172px;
}

.chatFlowMultiVideoThumb {
    position: absolute;
}

.chatFlowCommentBox {
    border-radius: 8px;
    border: solid 1px #dadada;
    float: left;
    min-width: 580px;
    background-color: #ffffff;
}

.chatFlowCommentBoxTitle {
    background-color: #027aaa;
    border-radius: 7px 7px 0px 0px;
    height: 40px;
}

.chatFlowCommentBoxTitle p {
    color: #ffffff;
    font-size: 14px;
    float: left;
    margin: 10px 15px 9px 15px !important;
}

.chatFlowCommentBoxTextBoxContainer {
    height: 100px;
}

.chatFlowCommentBoxTextBox {
    margin: 5px;
    font-size: 14px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    max-height: calc(100% - 10px);
    resize: none;
    border: none;
}

/* .chatFlowVoiceBoxContainer {
    padding: 5px 10px;
    float: left;
    background-color: #027aaa;
    margin: 0px 15px 0px 0px;
    border-radius: 6px;
} */

.chatFlowVoiceAttacmentButton {
    background-color: #027aaa;
    border-radius: 25px;
    height: 35px;
    width: 35px;
}

.chatFlowVoicePlayPause {
    float: left;
}
.chatFlowVoiceSpectrogram {
    float: left;
    margin: 0px 0px 0px 10px;
}
/* .SeverityBGDiv {
    float: left;
    width: 4.875rem;
    height: 24px;
    border-radius: 4px;
    text-align: center;
} */

/* .ExtremeValue {
    font-size: 0.75rem;
    font-weight: normal;
    color: #fff;
} */

/* .LinkButtonDiv {
    display: inline-block;
} */

/* .LinkButtonView {
    border: none;
    background: none;
    width: 25px;
} */

/* .LinkButtonView1 {
    border: none;
    background: none;
    width: 35px;
} */

/* .HeaderFireAlert {
    display: flex;
    align-content: center;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    margin-left: 15px;
    margin-right: 15px;
} */

.NameFire {
    margin: 0px;
    display: inline-block;
    font-size: 0.75rem;
    color: #fff;
    font-weight: 300;
}

.chatFlowMobileBtmOne1 {
    display: flex;
    align-items: center;
    margin: 25px 15px 0px 15px;
}
/* .OverlayChat {
    position: fixed !important;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    overflow-x: auto;
    scroll-behavior: smooth;
    width: auto !important;
    overflow-y: hidden;
    z-index: 99999;
} */

.MinimizeChatBox {
    width: 380px;
    background-color: #E6E9F0;
    float: left;
}

.MinimizeChatBox .chatFlowMobileHeaderTop {
    position: unset;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #027aaa;
    border: solid 0.5px #fff;
}

.MinimizeChatBox .FireAlertTextp {
    color: #ffffff;
}

.EnterTextDiv {
    height: 60px;
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    align-items: center;
}

/* .chatbot-associated-devices-list {
    overflow-y: auto;
    height: 145px;
    margin-bottom: 10px;
} */

/* .chatbot-live-camera {
    width: 92%;
    height: 100%;
    padding: 10px;
    border-radius: 21px;
    box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.08);
    background-color: #fff;
    margin: 10px;
} */

.chatbot-inner-container {
    height: 500px;
    background-color: #f0f3f5;
    border-radius: 3px;
    margin-bottom: 15px;
    border-radius: 5px;
    overflow: overlay;
}

.react-functional-chatbot-main-div {
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
}

/* .react-functional-chatbot-inner-div {
    margin: 10px;
} */

/* .chatflow-page-container-div {
    height: calc(100vh - 233px);
    overflow: overlay;
} */

/* .multi-chatbot-container-div {
    height: 430px;
    overflow: overlay;
} */

.alert-report-section {
    height: 745px;
}
/* .stat-officials-icon {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    padding: 7px;
} */

/* .chatFlowMobileBtmTwo {
    margin: 0px 15px 0px 15px;
    float: left;
} */

/* .TwoWayAudioToggle {
    width: 39px;
    height: 39px;
    border-radius: 22px;
    margin: 0px 6px 0px 6px;
    background: #027AAA 0% 0% no-repeat padding-box;
    border: 0.5px solid #006B96;
    box-shadow: 0 13px 10px rgb(6 50 149);
} */

/* .two-way-and-hooter-on-off {
    height: 35px !important;
    width: 35px !important;
    margin-top: -5px;
}

.two-way-and-hooter-on-off-span {
    float: right;
} */

/* .TwoWayAudioToggle1 {
    width: 39px;
    height: 39px;
    border: 0.5px solid #006B96;
    margin: 0px 6px 0px 6px;
    border-radius: 50%;
    background: #027AAA;
    box-shadow: inset -10px -10px 20px #025d81,
        inset 10px 10px 20px #0297d3;
} */

/* .hooter-twoway-div {
    display: flex;
    justify-content: end;
    margin: 17px 15px 0px 0px;
} */

/* .reasonCodeBtn {
    background-color: #4CAF50;
    border: none;
    padding: 15px 15px;
    padding-right: 35px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    background-color: #FFFFFF;
    color: black;
    font-size: 14px;
    border-radius: 7px 7px 7px 7px;
    margin: 7px 0px 0px 5px;
    width: 320px;
} */

.reasonCodeBtnn {
    padding: 15px 15px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    background-color: #EAF7FC;
    color: #2E92B9;
    font-weight: bold;
    font-size: 14px;
    border-radius: 7px 7px 7px 7px;
    margin: 7px 0px 0px 5px;
    width: 320px;
}

.reasonCodeBtn1 {
    background-color: #4CAF50;
    /* Green */
    border: none;
    /* color: white; */
    padding: 5px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    background-color: #FFFFFF;
    color: black;
    font-size: 14px;
    border-radius: 7px 7px 7px 7px;
    margin: 7px 0px 0px 36px;
    width: 315px;
    height: 33px;
}

/* .reasonCodeHeader {
    text-align: center;
    display: block !important;
    color: #767676;
    margin-left: 0px !important;
} */

/* dropdown down arrow color */
.css-tj5bde-Svg {
    color: #4283bd;
}

.react-flow {
    position: sticky !important;
    height: 95% !important;
}

/* .selected-reasoncode {
    background-color: #EAF7FC;
    color: #2E92B9;
    font-weight: bold;
} */

/* .checkmark-circle-reasoncode {
    position: absolute;
    top: 50%;
    left: 30%;
    transform: translate(-50%, -50%);
    float: left;
    padding: 0px !important;
    margin: 1px 0px 0px 1px;
    height: 22px;
} */

/* .checkmarkcircle-visible {
    display: block;
} */

/* .checkmarkcircle-hidden {
    display: none;
} */
.workflowinputrow {
    width: 70%;
}


.sample-doc-download {
    margin-left: 4px;
}

.workflowPreview {
    width: 950px !important;
    height: 640px !important;
}

[data-title]:hover:after {
    opacity: 1;
    transition: all 0.1s ease 0.5s;
    visibility: visible;
  }

[data-title]:after {
    content: attr(data-title);
    position: absolute;
    top: 2.4em;
    right: 0;
    padding: 4px 4px 4px 8px;
    background-color: #000;
    font-size: 12px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    opacity: 0;
    z-index: 999999;
    visibility: hidden;
}   
/* button[title]:hover:after {
    content: attr(title);
    position: absolute;
    top: -100%;
    left: 0;
    background-color: #000;
    font-size: 12px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    opacity: 0;
    z-index: 999999;
    visibility: hidden;
  }
[data-title]:after {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    background-color: #333;
    color: #fff;
    padding: 10px;
} */
/* .fullscreentoptooltip [data-title]:after {
    content: attr(data-title);
    position: absolute;
    top: 4.4em !important;
    right: 0;
    padding: 4px 4px 4px 8px;
    background-color: #000;
    font-size: 12px;
    border-radius: 5px;
    color: white;
    white-space: nowrap;
    opacity: 0;
    z-index: 999999;
    visibility: hidden;
}    */

/* button[data-title]:hover::after {
    content: attr(data-title);
    position: absolute;
    top: -100%;
    left: 0;
    z-index: 100;
    background-color: aquamarine;
    width: 55px;
  } */

[data-title] {
    position: relative;
}
/* .Ass_Name{
    display: block;
} */

/* Chatflow css ends */


/* Chatbot library css Start*/

.react-chatbot-kit-chat-input-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: none !important;
}

.react-chatbot-kit-chat-container {
    position: relative;
    width: auto !important;
}

.react-chatbot-kit-chat-bot-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 1.2rem;
    margin-right: 12.5px;
    background-color: #d8d8d8;
    display: none !important;
    justify-content: center;
    align-items: center;
}

.react-chatbot-kit-chat-bot-message {
    background-color: transparent !important;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.9rem;
    color: #fff;
    font-weight: medium;
    position: relative;
    width: 100% !important;
    margin-left: initial;
    text-align: left;
}

.react-chatbot-kit-chat-inner-container {
    height: 100%;
    background-color: #f0f3f5 !important;
    border-radius: 3px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.react-chatbot-kit-chat-bot-message-arrow {
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #f0f3f5 !important;
    position: absolute;
    left: -7px;
    top: 13px;
}

.react-chatbot-kit-chat-bot-message-container {
    display: grid !important;
    margin: 15px 0;
    justify-content: flex-start;
}

.react-chatbot-kit-user-avatar {
    display: none !important;
}

.react-chatbot-kit-user-avatar-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-left: 12.5px;
    background-color: #3d4e8d;
    display: none !important;
    justify-content: center;
    align-items: center;
}
/* Chatbot library css End */

/* By Rupali check untill now */
.ArrowSelectClass {
    /* background-position: calc(100% - 5px) 8px, calc(100% - 10px) 15px, 100% 0 !important; */
    background-position: calc(100% - 20px) 16px, calc(100% - 10px) 15px, 100% 0 !important;
}

h1 {
    color: var(--desktop-text-color);
    padding: 32px;
    font-size: 1.5rem;
  }
  
  .pane {
    position: absolute;
    width: var(--pane-default-width);
    height: var(--pane-default-height);
  
    display: grid;
    grid-template-rows: var(--title-bar-height) 1fr;
  
    border-radius: var(--pane-border-radius);
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.5);
  
    overflow: clip;
  
    min-width: 160px;
    min-height: 160px;
  }
  
  .pane .title {
    cursor: move;
  
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    /* background-color: #0b70d1; */
    height: 50px;
    background: #0B70D1 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000066;
    border-radius: 15px 15px 0px 0px;
  
    /* background-color: var(--title-bar-color); */
    /* color: var(--title-bar-text-color); */
  }
  
  .pane h2 {
    font-size: 1rem;
    font-weight: 400;
  }
  
  .pane .content {
    position: relative;
    background-color: var(--pane-content-background-color);
  }
  
  /* make image fill the space */
  .pane .content img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  
    object-fit: cover;
  
    -webkit-user-drag: none;
    user-drag: none;
  }
  
  /* add a corner in bottom right */
  .pane .corner {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 24px;
    height: 24px;
  
    /* north west to south east */
    cursor: nwse-resize;
  
    /* background-color: red; */
  }

  .AssociatemainDiv {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-gap: 3px;
    min-width: 60vw;
    min-height: 60vh;
    overflow: hidden;
    background-color: #fff;
    .HeightChildDiv {
        &:nth-child(1) {
            grid-column: auto / span 3;
            grid-row: span 3;
        }
        &:nth-child(2) {
            grid-column: auto / span 3;
            grid-row: span 3;
        }
        &:nth-child(3) {
            grid-column: auto / span 3;
            grid-row: span 3;
        }
        &:nth-child(4) {
            grid-column: auto / span 3;
            grid-row: span 3;
        }
    }
  }

  .box {
    background-color: #fff;
    border-radius: 5px;
    /* box-shadow: 0 2px 3px rgba(10,10,10,.1), 0 0 0 1px rgba(10,10,10,.1); */
    display: block;
    /* padding: 1.25rem; */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 12px 5px rgba(0,0,0,0.5);
  }
  
  .box .title {
    color: #363636;
      font-size: 2rem;
      font-weight: 300;
      line-height: 1.125;
      height: 50px;
      background: #0B70D1 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 6px #00000066;
      border-radius: 15px 15px 0px 0px;
      cursor: move;
      padding: 15px;
  }
  .react-draggable {
    z-index: 99999;
  }
  .modal-backdrop.show {
    z-index: 99998 !important;
}

[data-title]:after {
    /* content: attr(data-title); */
    position: absolute;
    top: -70%;
    box-shadow: 0px 0px 2px 2px rgba(174,174,174,0.64);
    -webkit-box-shadow: 0px 0px 2px 2px rgba(174,174,174,0.64);
    -moz-box-shadow: 0px 0px 2px 2px rgba(174,174,174,0.64);
    z-index: 1;
}
.AssociateDataTitle > [data-title]:after {
    /* content: attr(data-title); */
    position: absolute;
    bottom: -80%;
    box-shadow: 0px 0px 2px 2px rgba(174,174,174,0.64);
    -webkit-box-shadow: 0px 0px 2px 2px rgba(174,174,174,0.64);
    -moz-box-shadow: 0px 0px 2px 2px rgba(174,174,174,0.64);
    z-index: 1;
}
.swal2-container.swal2-backdrop-show {
    z-index: 99999;
}
.Toastify {
    z-index: 99999 !important;
}