$color_1: #182e37;
$color_2: #000000;
$color_3: #4a595f;
$color_4: #131415;
$color_5: #212529;
$color_6: #748b94;
$color_7: #ffffff;
$color_8: #f9c44c;
$background-color_1: #ffffff;
$background-color_3: #fffefb;
$background-color_5: unset;
$background-color_6: #f5f5f5;
$background-color_7: #dbe6fc;
$background-color_8: #748b94;
$background-color_9: #0b70d1;
$background-color_10: #53a3c5;
$background-color_11: #DA8012;
$background-color_12: #6459D6;
$background-color_13: #27AD93;
$background-color_14: #1D8CDB;
$background-color_15: #f2f6fe;
$box-shadow_1: 0 0 6px 0 rgba(0, 0, 0, 0.3);


.lightTheme{		
	.MainContentMargin {
		margin: 0px 10px;
		height: 100%;
	}
	.dashboardHeadingInnerDiv {
		margin-top: 15px;
		margin-bottom: 7px;
		height: 32px;
	}
	.dashboardScroll {  
		height: calc(100vh - 140px);
		display: flex;
    flex-direction: column; 
		@include devices(laptop_xxl) {
			/* height: 750px; */
			/* overflow-y: auto;
			overflow-x: hidden; */
		} 
		@include devices(laptop_xl) {
			// height: 730px;
			/* height: calc(100% - 50px); */
			overflow-y: auto;
			overflow-x: hidden;
		} 
		@include devices(laptop_lg) {
			/* height: 610px; */
			overflow-y: auto;
			overflow-x: hidden;
			padding-top: 10px;
		}  		
		@include devices(laptop_md) {
			/* height: 655px;  */   
		}  
		@include devices(laptop_sm) {
			/* height: 520px;    */ 
		}		
		@include devices(laptop_xs) {
			/* height: calc(100vh - 110px); */
		}
		.grid-container {
			@include devices(laptop_xs) {
				display: block;
			}
		}
    }
	.dashboardheadingMain {
		width: 100%;
		@include floatLeft;
		margin-bottom: 0px;
	}
	.dashboardheading {
		@include floatLeft;
		p {
			font-size: 1.5rem;
			@include fontStyle;
			color: $color_1;
			margin-bottom: 0;
			span {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
	}
	.RowClass {
		width: 100%;
		@include floatLeft;
		padding: 10px 0px;
		margin: 0;
	}
	.grid-container--fit {
		grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
	}
	.grid-container {
		display: grid;
	}
	.KPI_Margin {
		margin: 0px 10px;
	}
	.DashboardKpi {
		@include devices(laptop_xs) {
			/* margin: 0px 10px; */
			width: 48%;
			display: inline-block;
		}
	}
	.DashboardMargindiv {
		@include devices(laptop_xs) {
			margin-top: 20px;
		}
	}
	.topKpiCard {
		height: 8rem;
		border-radius: 13px;
		cursor: pointer;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
		margin: 0px 0px 20px 0px;
		background-color: $background-color_1;
	}
	.AlertsCount {
		display: inline-flex;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}
	.dashboard-card-content {
		margin: 1.8rem;
		height: auto;
		@include display-flex;
	}
	.dashboard-card-image {
		width: 3rem;
		height: 3rem;
		opacity: 0.8;
		border-radius: 9px;
		background-color: $background-color_1;
		text-align: center;
		@include flexCenter;
	}
	.dashboard-icon {
		height: 1.5rem;
	}
	.dashbord-card-txt {
		margin-left: 20px;
		height: 3rem;
	}
	.NumberText {
		font-size: 21px;
		line-height: 23px;
		font-weight: bold;
		text-shadow: 0 2px 20px $color_8;
		color: $color_2;
		@include ValueText-Style;
	}
	.TextValue {
		font-size: 17px;
		line-height: 15px;
		font-weight: normal;
		margin-left: 0.2rem;
		color: $color_2;
		@include ValueText-Style;		
	}
	.bottomKpiCard {
		height: 100%;
		padding: 0 0 0 1.188rem;
		border-radius: 13px;	
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
		background-color: $background-color_3;
	}
	.kpi2 {
		width: 100%;
		@include flexSpaceBetween;
	}
	.smallKpiText {
		p {
			margin-bottom: 2px;
			&:first-child {
				font-size: 1.25rem;
				font-weight: 600;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: $color_1;
				white-space: nowrap;
				width: 15rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			&:last-child {
				font-size: 0.75rem;
				font-weight: 300;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: $color_3;
			}
		}
	}
	.ovalDiv {
		background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALsAAAC7BAMAAADPvvJZAAAAFVBMVEXr6+vq6upHcEzo6Ojs7Ozr6+vq6urEsnt9AAAAB3RSTlMaSAB8DDFjT9RwyQAABOVJREFUeNrtnE1u2zAQhWmB3lswpLUQ8AKBgK4FQQewFz6AIUD3P0KTtqlNcn45YpIC5Tr9PH4zUq2ZN3Iv0rMsi3fOeb8ss/TfDE70Z2GZXHSWZT98SNh/PmEfPAyXfQCPXxx+/GzE46GLvgCDXxx36C9A4ycnOHMpXkQn+RReSKf4BF5MJ/g4XkHH+SheRUf5GD7o6M6r8Fo6xkfwTn8aOX4qwIPyg/jgio4QX0iH5IHwUyEekAfAD674SPDl9FyeHG8I3jkWHyz07OLK8JMJn2Y3xduCz8JP8cbg0/ATPBn87da1b6frbuLwEzwe/PHaPp3uIgs/xgcZnPwAj+Ox4G8tcDpB+DFeGjr5BTyGh4M/tui5cJduhNfSEX4D4wc1HeHD+ElPh/kzhA8l9LYlk/uEh7S58viOUucJX0YH+U2OD0XSwPL7HA8kthUePLkPfKE0sDw+xQ+l0sDypPipPHgo/DnBZ39waBXnhNTOBz5Yggey62P8ZAoeCH+O8MVFiYXfPOODoWzg4vHP+MGmPFQ8z/jJGnwe/vyENwefh9888EGZ2H5bx3HcNiq5/oEfNFXZb+PH+UHV5gM/ybT5FXN0Xgl15r94UWK3ETh3PLnNBz4ItOlH8Lzi6qD4q5g+joQ6H/iB1eaM0aPwEfzEaYPGHhfPKcvtLzynDUGP8F0m/js+cNfUSuBH/MqC8Ue58HRp+t/4gZa+J+lUaf7GT7T0K40nxJ8hvC74+L4D4WnpmeDj3F6S3L7hAyk9F3yMP2nxZxZ/R/H+HT+QmR1V+CS3AF6rTVSZbY6fqMyuSnyc25nDj2Y8lVmBNtR/uE2GvyrrJsF3KT5Q+NGG9xleLX18XbUa/Hlv/NGKvyT4gcCvRrxL8Qe99NFdIanM6vgJx/dm/JTgr/rMfiq+k+PXfw1fUPbxHblN8MQdbTRH76jox92j3xnvdsDf6+Jf/+N3wV+teEXdV75q192j3xk/fSH+vDv+YMWf5Pj+++M/9WfUsQD/Kv8RWPIb807h7T/A76W/7/vd8fqHH+K5mcOvNnzDPHietXjuwfOoF5/ouOzwVD5qHvpdq1aHaEdxDZdeh+cbLup+Dt6r83t0o37Q3SimS6q6o11y/Avd4101eJe36pg2aa/AS9qkB2X4d6LDDvSQsxa1vC4vUA+Za7CfxXgHdcDZ0ckqrMsOxLNzpV6Y2Qs4Hkhze9TMTgj8DE9+gMFSL5FeOFiCxmKbQPoTgpcM9foS6WE8PJLcmIsKGtjCE88DMaUVazOj81pyHLxtkmvqeV4blMPsv59wx+vGE7NyfhT/plQ8zL7gs/LcZGE3EsyUT8Fsg3Cky8Js4vC0R8RqQYk8IoC56GpS3nH+HJv9x3PuIpt5qWG9USbr1cw6uyzGMSfwpRlsb43EVVds2gNcdWFHy6GXORpLDZOAo7HMTMrYSRk3aZlZFXSTFhh5WSsv6+QtMAo7jQ9Zb3NuVC5qtH4wl/mM4FEDO2gxvwks7DIHu8YgTzjYieUBsb3fF20PPJYTrrebePlBs/sgOv4LNzdq751U3poxLSzlK0ufvbFUe9/KkF3/DXbdam/qFfL9N9mSrL3jWXtDtfJ+rWSjnLxcv3i3ufZmdu298tpb8bV3+iX1aXkjQe33KXACWd8GUf1dFrXfxFH9PSKGt6D8BLiLfY+cZ3bGAAAAAElFTkSuQmCC);
		background-position: fixed !important;
		background-repeat: no-repeat;
		// background-position: center;
		width: 20%;
		height: 5rem;
		right: -2rem;
		@include flexCenter;
	}
	.WrapperTable {
		display: grid;
		grid-template-columns: 74% 25.5%;
		grid-gap: 1em;
		min-height: 570px;
	}
	.alertTable {
		display: inline-block;
		width: 100%;
		border-radius: 12px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
		background-color: $background-color_1;
		padding: 1.156rem 0 1.031rem 0;
		height: 100%;
	}
	.alertTableList {
		@include floatLeft;
		width: 100%;
		padding: 0 1.594rem 0 1.25rem;
		p {
			@include floatLeft;
			color: $color_2;
		}
	}
	.removeClickStyle {
		width: 98% !important;
		margin-bottom: 1rem;
		margin: 12px;
		height: 80%;
	}
	.sticky-table {
		tbody {
			display: block;
			overflow: auto;
			font-size: 15px;
			border: 1px solid #d4d4d4;
			tr {
				display: table;
				width: 100%;
				table-layout: fixed;
				border-bottom: 0;
				&:hover {
					color: $color_5;
				}
				td {
					vertical-align: middle !important;
					border-top: none !important;
					border-bottom: none;
					word-break: break-word;
					padding: 5px 10px;
					&:first-child {
						padding-left: 20px;
						img {
							padding-right: 8px;
						}
					}
					&:last-child {
						img {
							padding-right: 20px;
						}
					}
				}
				&:active {
					color: $color_5;
				}
				&:nth-of-type(odd) {
					background-color: $background-color_1 !important;
				}
				&:nth-of-type(even) {
					background-color: $background-color_6;
				}
			}
		}
		thead {
			td {
				font-weight: 600;
			}
			display: table;
			width: 100%;
			table-layout: fixed;
			background-color: $background-color_7;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			border-top: 0px;
			width: 100%;
			font-weight: 500;
			tr {
				td {
					&:last-child {
						border-right: none !important;
					}
				}
			}
		}
		td {
			font-size: 13px;
			@include fontStyle;
			color: $color_4;
			padding: 10px 10px;
			border-top: 0px !important;
			background-color: $background-color_5 !important;
			&:first-child {
				padding-left: 20px;
			}
		}
	}
	.tbody-dashboard {
		/* height: calc(100% - 75px) !important;
		@include devices(laptop_xs) {
			height: calc(100% - 88px) !important;;
		} */
	}
	.sticky-table tbody{
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $background-color_8;
			border-radius: 100px;
		}
	}
	.kpi-card {
		padding: 10px;
		border-radius: 8px;
		box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
		background-color: $background-color_1;
		display: flex;
    	flex-direction: column;
	}
	.section-head {
		@include display-flex;
		padding: 10px;
		/*width: 17.75rem;
		 margin-bottom: 0.2rem; */
		 .location-alerts {
			width: 12.625rem;
			height: 1.438rem;
			font-size: 1.0rem;
			color: $color_1;
			@include fontStyle;
		}
	}
	.top-three-sites {
		width: 84px !important;
	}
	.bottom-three-sites {
		width: 100px !important;
	}
	.tabs-list {
		width: 100%;
		height: 2.3rem;
		padding: 0.18rem 5px 0 0px;
		border-radius: 18px;
		box-shadow: 0 3px 15px 0 #00000014;
		border: solid 1px $color_6;
		background-color: $background-color_1;
		text-align: center;
	}
	.active-sub-menu span {
		font-weight: 600;
		color: $color_5;
		font-size: 14px;
	}
	.nav-link {
		/* width: 100%; */
		height: 1rem;
		margin: 0.438rem 0 0.375rem 1.313rem;
		font-size: 0.75rem;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: center;
		color: $color_6;
		margin: 0;
		height: auto;
	}
	.nav-item {
		>.active {
			width: 100%;
			height: 1.813rem;
			margin-left: 0.2rem;
			padding: 0.4rem 0.2rem;
			border-radius: 15.5px !important;
			background-color: $background-color_9 !important;
			margin-top: 0.01rem;
		}
	}
	.location-list-item {
		width: 100%;
		height: 2.625rem;
		margin: 0.5rem 0;
		padding: 0.5rem 0.5rem;
		border-radius: 8px;
		border: solid 1px #e9e9eb;
		background-color: $background-color_1;
		&::before {
			content: "";
			width: 8px;
			height: 8px;
			border-radius: 50%;
			margin-right: 5px;
			display: inline-block;
			background-color: $background-color_10;
			vertical-align: middle;
		}
	}
	.qc-actions {
		@include floatRight;
		padding: 0 0 0 0.6rem;
		>img {
			padding: 0 0 0 0.8rem;
		}
	}
	.manage-config {
		margin: 1rem;
		padding: 0.3rem 1rem;
		border-radius: 15.5px !important;
		background-image: linear-gradient(244deg, #0f62acc9 26%, #0f62ac);
		border: none;
		align-self: center;
		&:focus {
			outline: none;
		}
		>span {
			width: 9.25rem;
			height: 1.188rem;
			font-size: 0.875rem;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: $color_7;
		}
	}
	.manage-config-align {
		@include flexCenter;		
	}
	.inactive-text-dashboard {
		width: 100px !important;
		text-align: center;
		font-size: 0.75rem;
		font-weight: normal;
		color: $color_6;
		display: inline-block;
		margin-left: 8px;
		cursor: pointer;
		text-decoration: none;
		&:hover {
			text-decoration: none;
			color: $color_6;
		}
	}
	.template-list {
		height: 157px !important;
		overflow-y: scroll;
		scroll-behavior: smooth;
		flex: 1 1 auto;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $background-color_8;
			border-radius: 100px;
		}
	}
	.noRecordFound {
		position: relative;
		height: 100%;
	}
	.noRecordFoundText {
		position: absolute;
		top: 50%;
		left: 46%;
		font-size: 0.9rem;
		color: $color_2;
	}
	.dashboard-quickconfig-alerttemplate-outer {
		@include display-flex;
		box-shadow: 0 3px 15px 0 #00000014;
		border: solid 1px $color_6;
		border-radius: 18px;
		width: 100%;
	}
	.dashboard-quickconfig-alerttemplate-inner {
		height: 30px;
		padding: 5px;
		margin: 3px;
		border-radius: 15.5px;
		background-color: $background-color_9;
	}
	.dashboard-quickconfig-alerttemplate-text {
		color: $color_7;
		font-size: small;
		padding: 0rem 0.5rem;
	}
    .systemHealth {
        box-shadow: $box-shadow_1;
        background-color: $background-color_1;
        /* height: 100%; */
        border-radius: 13px;
        margin: 0px 10px;
        @include floatLeft;
		@include devices(laptop_xs) {
			width: 48%;
		}
		// &:hover, &:focus, &:active {
		// 	box-shadow: 0 8px 16px 3px rgba( #000, .6 );
		// 	transform: translateY( -3px ) scale( 1.05 ) rotateX( 15deg )
		// }
		.chartsStatusDiv {
			display: flex !important;
			justify-content: space-between !important;
			float: left !important;
			width: 100% !important;
			margin-top: 10px !important;
			align-content: center !important;
			align-items: center !important;
		}
    }
    .statusValueText {
        color: $color_3;
    }
    .statusValueP {
        color: $color_2;
    }
	.viewAllListBtn {
		@include floatRight;
		padding: 0.219rem 0.781rem 0.156rem 0.844rem;
		border-radius: 12px;
		background-color: $background-color_9;
		font-size: 0.875rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1;
		letter-spacing: normal;
		text-align: right;
		height: 32px;
		p {
			color: $color_7;
			font-size: small;
			padding: 0rem 0.5rem;
			margin-bottom: 0px !important;
		}
		&:hover {
			// background-color: #005aad;
			// transition: 0.5s;
			// cursor: pointer;

			background: #005aad radial-gradient(circle, transparent 1%, #005aad 1%) center/15000%;
  			transition: background 0.8s;
		}
		&:active {
			background-color: #ffffff;
			background-size: 100%;
			transition: background 0s;
		  }
	}
	.card-body {
		padding: unset;
		padding-top: 20px;
	}
	.template-name {
		width: 70%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.template-list {
		/* height: 174px; */
		overflow-y: scroll;
		scroll-behavior: smooth;
	}
	.dashboard_configuration_templateName {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		width: 75%;
		font-size: 0.75rem;
		@include devices(laptop_lg) {
			width: 68%;
		}  		
	}
	.template-list-item {
		display: inline-flex;
		align-content: stretch;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
	}
	.pointerItem {
		cursor: pointer;
	}
	.DayCount {
		height: 40px;
		@include flexSpaceBetween;
		margin-left: 15px;
		margin-right: 15px;
	}	
	.DayText {
		font-size: 0.75rem;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: 0.75px;
		text-align: center;
		color: $color_1;
	}
	.today {
		height: 25px;
		width: 25px;
		border-radius: 10px;
		background-color: $background-color_1;
		color: $color_2;
		padding: 0.3rem;
		box-shadow: 0 0 11px rgba(33, 33, 33, .2);
		border-radius: 50%;
		display: inline-block;
	}
	.AlertKPIBG {
		background-color: $background-color_11;
	}
	.SiteKPIBG {
		background-color: $background-color_12;
	}
	.CameraKPIBG {
		background-color: $background-color_13;
	}	
	.OperatorKPIBG {
		background-color: $background-color_14;
	}
	.SeverityKpiBG {
		background-color: $background-color_13
	}
	.KpiUpperDiv {
		@include display-flex;
		justify-content: space-between;
	}

	.DashboardKpiNumber {
		font-size: 1.125rem;
		font-weight: bold;
		font-stretch: normal;
		color: $color_7;
		margin: 0px;
		@include line-height;
		/* margin-left: 0.2rem; */
	}
	.DashboardKpiText {
		font-size: 1.2rem;
		color: $color_7;
		text-shadow: 0 2px 20px $color_8;
		margin: 0px;
		@include fontStyle;
	}
	.kpiTextValue {
		/* font-size: 1.5rem; */
		color: $color_7;
		text-shadow: 0 2px 20px $color_8;
		margin: 0px;
		@include fontStyle;
	}
	.KpiNumberValue {
		/* ont-size: 1.125rem; */
		font-weight: bold;
		font-stretch: normal;
		color: $color_7;
		margin: 0px;
		@include line-height;
		/* margin-left: 0.2rem; */
	}
	.StatusInActive {
		@include statusStyle;
		background-color: red;
		border: 2px solid $color_7;
	}
	.StatusActive {
		@include statusStyle;
		background-color: green;
		border: 2px solid $color_7;
	}
	/* dashboard kpi changes css end */

	
	.StatusHeaderDiv{
		min-width: 67vw;
		@include flexSpaceBetween;
	}
	.StatusTableDiv{
		overflow: auto;
		height: 70vh;
	}
	.StatusTableDiv thead{
		background-color: #dbe6fc;
		height: 85px; 
		position: sticky; 
		top: 0; 
		z-index: 1;
	}
	.StatusTableDiv thead th{
		text-align: left;
		color: #535353;
		font-size: 15px;
		border-top: none;
		width: 12vw;
	}
	.StatusTableDiv thead th p{
		margin-bottom: 0px !important;
	}
	.StatusTableDiv thead th:first-child{  
		border-top-left-radius: 5px;
	}
	.StatusTableDiv thead th:last-child{  
		border-top-right-radius: 5px;
	}
	.StatusTableDiv th, td {
		text-align: left;
	}
	.StatusTableDiv td{
		font-size: 0.8vw;
		padding:  0.3vw 0.3vw 0.3vw 1vw !important;
	}  
	.StatusSearchText .SearchViewInput{
		width: 90%;
	}
	.DateTimeTableSpan{
		margin-right: 3vw;
	}
	.tbody-dashboard tr:hover td {
		background-color: $background-color_15 !important;
	}
	.integratedDevice {
		width: 100%;
		float: left;
		p {
			margin: 0;
			font-family: Segoe UI;
			font-size: 16px;
			font-weight: normal;
			letter-spacing: 0.01px;
			color: #272727;
			@include devices(laptop_lg) {
				font-size: 14px;
			}
			@include devices(laptop_md) {
				font-size: 14px;
			}
			@include devices(laptop_sm) {
				font-size: 14px;
			}
		}
	}
}