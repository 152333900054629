$color_1: black;
$color_2: red;
$color_3: #BABABA;
$color_4: #83ba2b;
$color_5: #daa926;
$color_6: #d06841;
$color_7: #dd2121;
$color_8: #027aaa;
$color_10: #0f6689;
$color_11: #b8b8b8;
$color_12: #d5d5d5;
$color_13: #d9d9d9;
$color_14: #676767;
$background-color_1: transparent;
$background-color_2: #0b70d1;
$background-color_3: #83ba2b;
$background-color_4: #daa926;
$background-color_5: #d06841;
$background-color_6: #dd2121;
$background-color_7: #f0f3f5;
$background-color_7: #1F1A24;
$background-color_8: #070612;
$background-color_9: #484848;
$background-color_10: #3d3d3d;
$box-shadow_1:rgb(112 111 111) 0px 0px 5px 0px inset, 0 0 6px 0 rgba(0, 0, 0, 0.3);

.darkTheme{
        .cameramainheader {
            width: 30%;
            display: inline-flex;
            align-items: center;
        }
        .AlertSearchTextDiv {
            display: inline-block;
            border-radius: 8px;
            background-color: $background-color_8;
            padding-left: 10px;
            margin-top: 7px;
        }
        .EyeIcon {
            cursor: pointer;
            color: $color_1;
            &:hover {
                cursor: pointer;
                color: $color_2;
            }
        }
        .SecondDivSearch {
            .SearchViewInput {
                width: 80%;
            }
        }
        .RedBorderDiv {
            height: 37px;
            border-radius: 19px;
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px #748b94;
            background-color: $background-color_1;
            @include flexSpaceBetween;
            align-content: center;
            flex-wrap: nowrap;
        }
        .SeverityTextDiv {
            text-align: start;
        }
        .active-text {
            font-size: 0.75rem;
            font-weight: normal;
            font-style: normal;
            line-height: 2;
            letter-spacing: normal;
            // color: $color_3;
            color: #dddddd;
            margin-bottom: 0px;
        }
        .active-block {
            width: 130px;
            height: 28px;
            border-radius: 15.5px;
            padding: 2px;
            text-align: center;
            margin-left: 6px;
            display: inline-block;
        }
        .active-all {
            background-color: $background-color_2;
        }
        .active-all-light {
            background-color: $background-color_2;
        }
        .inactive-text {
            width: 130px;
            text-align: center;
            font-size: 0.75rem;
            font-weight: normal;
            display: inline-block;
            margin-bottom: 0px;
            cursor: pointer;
        }
        .low {
            color: $color_4 !important;
        }
        .medium {
            color: $color_5 !important;
        }
        .moderate {
            color: $color_5 !important;
        }
        .high {
            color: $color_6 !important;
        }
        .extreme {
            color: $color_7 !important;
        }
        .all {
            color: $color_8 !important;
        }
        .low-bg {
            background-color: $background-color_3 !important;
        }
        .medium-bg {
            background-color: $background-color_4 !important;
        }
        .moderate-bg {
            background-color: $background-color_4 !important;
        }
        .high-bg {
            background-color: $background-color_5 !important;
        }
        .extreme-bg {
            background-color: $background-color_6 !important;
        }
        .active-extreme {
            background-image: linear-gradient(207deg, #dd2121 65%, #d01d2b 91%);
        }
        .active-high {
            background-image: linear-gradient(207deg, #d06841 65%, #eb5822 91%);
        }
        .active-medium {
            background-image: linear-gradient(207deg, #daa926 65%, #e7b018 91%);
        }
        .active-low {
            background-image: linear-gradient(207deg, #6faa10 65%, #6a9721);
        }
        .low-bb {
            border-bottom: 5px solid #83ba2b;
        }
        .medium-bb {
            border-bottom: 5px solid #daa926;
        }
        .moderate-bb {
            border-bottom: 5px solid #daa926;
        }
        .high-bb {
            border-bottom: 5px solid #d06841;
        }
        .extreme-bb {
            border-bottom: 5px solid #dd2121;
        }
        .DownloadTextDiv {
            text-align: end;
            a {
                &:hover {
                    text-decoration: none;
                }
            }
        }
        .DownloadText {
            width: auto;
            text-align: center;
            font-weight: normal;
            display: inline-block;
            margin-bottom: 0px;
            font-size: 0.875rem;
            color: $color_3;
            margin-left: 10px;
            min-width: 120px;
        }
        .infinite-scroll-div {
            overflow-y: auto;
            width: 100%;
            height: calc(100vh - 200px);
            margin-right: 10px;
        }
        /* #scrollableDivOne {
            .infinite-scroll-component__outerdiv{
                height: 100%;
            }
            .infinite-scroll-component {
                height: 100% !important;
            }
        } */
        .infinite-scroll-div-list {
            overflow-y: auto;
            width: 100%;
            height: calc(100vh - 333px);
            margin-right: 10px;
            padding: 0px 10px;        
        }
        .GridBgImg {
            background-color: $background-color_7;
            margin: -2px 5px 5px 5px;
            border-radius: 7px 7px 0px 0px;
        }
        .GridBgImgChat {
            background-color: $background-color_7;
            margin: -2px 5px 5px 5px;
            border-radius: 7px 7px 0px 0px;
        }
        .TitlesOfGrid {
            margin-left: 10px;
            margin-right: 10px;
            .AlertViewButton {
                width: auto;
                height: 28px;
                border-radius: 15px;
                border: 1px solid $color_8;
                background-color: $background-color_1;
                margin-top: 5px;
                min-width: 110px;
                padding-left: 15px;
                padding-right: 15px;
                p {
                    font-size: 0.875rem;
                    @include fontStyle;
                    line-height: 14px;
                    color: $color_8;
                    margin: 0px;
                    cursor: pointer !important;
                    text-align: center;
                }
                &:focus {
                    outline: none;
                }
            }
        }
        .TitleGrid {
            font-size: 0.875rem;
            font-weight: bold;
            text-align: left;
            color: $color_3;
            margin-bottom: 4px;
            margin-top: 5px;
        }
        .OperateBy {
            font-size: 0.75rem;
            font-weight: 500;
            text-align: left;
            color: $color_11;
            margin: 0px;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        .OperateName {
            font-size: 0.75rem;
            font-weight: normal;
            text-align: right;
            color: $color_3;
        }
        .AlertViewButton {
            width: 110px;
            height: 28px;
            border-radius: 15px;
            border: 1px solid #027aaa;
            background-color: $background-color_1;
            margin-top: 5px;
            min-width: 110px;
            padding-left: 15px;
            padding-right: 15px;
            p {
                font-size: 0.875rem;
                @include fontStyle;
                color: $color_8;
                margin: 0px;
                cursor: pointer !important;
                text-align: center;
            }
            &:focus {
                outline: none;
            }
            @include devices(laptop_xl) {
                min-width: auto;
                padding-left: 0px;
                padding-right: 0px;
            }
            @include devices(laptop_lg) {
                min-width: auto;
                padding-left: 0px;
                padding-right: 0px;
            }
            @include devices(laptop_md) {
                min-width: auto;
                padding-left: 0px;
                padding-right: 0px;
            }
            @include devices(laptop_sm) {
                min-width: auto;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        .camera-name {
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .ButtonsDivAlert {
            display: flex;
            justify-content: center;
        }
        .SelectionAlertViewButton {
            width: 99px;
            height: 31px;
            border-radius: 15px;
            border: 1px solid #027aaa;
            background-color: $background-color_2;
            margin-top: 5px;
            &:focus {
                outline: none;
            }
            p {
                font-size: 0.875rem;
                @include fontStyle;
                // color: $color_3;
                color: #dddddd;
                margin: 0px;
                cursor: pointer !important;
                text-align: center;
            }
        }
        #divListView {
            width: 100%;
        }
        .MainListDiv {
            height: auto;
            border-radius: 10px;
            /* box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08); */
            border: 1px solid rgb(72 72 72);
            background-color: $background-color_1;
            // padding: 0px 10px;
        }
        .ListofAlerts {
            // height: 80px;
            border-bottom: 1px solid rgb(72, 72, 72);
        }
        .ListMain {
            // display: flex;
            // align-content: center;
            // align-items: center;
            display: grid;
            /* align-content: center; */
            grid-gap: 5px;
            // grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            // grid-template-columns: 5vw 5vw repeat(auto-fill, minmax(138px, 1fr));
            grid-template: repeat(2, auto)/repeat(12, minmax(133px, 1fr));
            align-items: center;
            @include devices(laptop_xl) {
                // grid-template-columns: 5vw 5vw repeat(auto-fill, minmax(120px, 1fr));
                grid-template: repeat(2, auto)/repeat(12, minmax(110px, 1fr));
            }
            @include devices(laptop_lg) {
                // grid-template-columns: 5vw 5vw repeat(auto-fill, minmax(105px, 1fr));
                grid-template: repeat(2, auto)/repeat(12, minmax(100px, 1fr));
            }
            @include devices(laptop_md) {
                // grid-template-columns: 5vw 5vw repeat(auto-fill, minmax(95px, 1fr));
                grid-template: repeat(2, auto)/repeat(12, minmax(92px, 1fr));
            }
            @include devices(laptop_sm) {
                // grid-template-columns: 5vw 5vw repeat(auto-fill, minmax(85px, 1fr));
                grid-template: repeat(2, auto)/repeat(12, minmax(85px, 1fr));
            }
            .AlertViewButton {
                width: 110px;
                height: 28px;
                border-radius: 15px;
                border: 1px solid $color_8;
                background-color: $background-color_1;
                margin-top: 5px;
                /* min-width: 110px; */
                padding-left: 15px;
                padding-right: 15px;
                p {
                    font-size: 0.875rem;
                    @include fontStyle;
                    line-height: 14px;
                    color: $color_8;
                    margin: 0px;
                    cursor: pointer !important;
                    text-align: center;
                }
                &:focus {
                    outline: none;
                }
                @include devices(laptop_xl) {
                    min-width: auto;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                @include devices(laptop_lg) {
                    min-width: auto;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                @include devices(laptop_md) {
                    min-width: auto;
                    padding-left: 0px;
                    padding-right: 0px;
                }
                @include devices(laptop_sm) {
                    min-width: auto;
                    padding-left: 0px;
                    padding-right: 0px;
                }
            }
            .SelectionAlertViewButton {
                width: 110px;
                height: 28px;
                border-radius: 15px;
                border: 1px solid $color_8;
                background-color: $background-color_2;
                margin-top: 5px;
                &:focus {
                    outline: none;
                }
                p {
                    font-size: 0.875rem;
                    @include fontStyle;
                    line-height: 14px;
                    // color: $color_3;
                    color: #dddddd;
                    margin: 0px;
                    cursor: pointer !important;
                    text-align: center;
                }
                @include devices(laptop_xl) {
                    width: auto;
                }
                @include devices(laptop_lg) {
                    width: auto;
                }
                @include devices(laptop_md) {
                    width: auto;
                }
                @include devices(laptop_sm) {
                    width: auto;
                }
            }
        }
        .AlertSnapshotgenericImg {
            height: 80px;
            width: 80px;
            @include devices(laptop_xl) {
                height: 70px;
                width: 70px;
            }
            @include devices(laptop_lg) {
                height: 70px;
                width: 70px;
            }
            @include devices(laptop_md) {
                height: 65px;
                width: 65px;
            }
            @include devices(laptop_sm) {
                width: 60px;
                height: 60px;
            }
        }
        .AlertSnapshotDbImg {
            height: 50px;
            width: 80px;
            margin: 10px 0px;
            @include devices(laptop_xl) {
                height: 70px;
                width: 70px;
                margin: 10px 0px;
            }
            @include devices(laptop_lg) {
                height: 70px;
                width: 70px;
                margin: 10px 0px;
            }
            @include devices(laptop_md) {
                height: 65px;
                width: 65px;
                margin: 10px 0px;
            }
            @include devices(laptop_sm) {
                width: 60px;
                height: 60px;
                margin: 10px 0px;
            }
        }
        .SituationTitleDiv {
            // width: 30%;
            // margin-left: 25px;
        }
        .OperateByDiv {
            // width: 25%;
            // margin-left: 10px;
            padding: 10px 3px 10px;
        }
        .ChartsButtonDiv {
            width: 40%;
            text-align: end;
            display: contents;
        }
        .scrollbar {
            width: 100%;
        }
        .cameramainheader {
            h4 {
                font-size: 1.5rem;
                font-weight: bold;
                text-align: left;
                color: $color_13;
                margin-bottom: 0px;
            }
        }
        .ChatBox {
            width: 430px;
            background-color: #545454;
            border: 2px solid #027aaa;
            @include floatLeft;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            box-shadow: 11px 6px 37px -19px rgba(0, 0, 0, 0.69);
            margin-right: 0.5rem;
            flex-grow: 1;
            min-width: 400px;
            max-width: 430px;
        }
        
        .SearchViewInput {
            border-radius: 8px;
            background-color: $background-color_1;
            border: none;
            height: 35px;
            padding: 5px;
            font-size: 0.75rem;
            width: 90%;
            color: $color_3;
            /* font-weight: 600; */
            :focus{
                outline: none;
            }
            &::placeholder {
                font-family: Arial;
                font-size: 13px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.15;
                letter-spacing: normal;
                text-align: left;
                color: $color_14;
            }
        }
        .AlertStatus, .AlertType1 {
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            width: 100%;
        }        
        .AlertStatus3 {
            width: 40%;
            padding-right: 10px;
            @include devices(laptop_xs) {
                width: 11%;
            }
        }        
        .AlertStatus4 {
            width: 25%;
            padding-right: 10px;
        }
        .AlertStatusType {
            width: 100%;
            // height: 5.188rem;
            height: 4.2rem;
            padding: 0.7rem;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
            background-color: $background-color_8;
            border-radius: 12px;
        }
        .AlertStatus1 p:first-child,
        .AlertStatus2 p:first-child,
        .AlertStatus3 p:first-child,
        .AlertStatus4 p:first-child {
            font-size: 0.865rem;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $color_3;
            margin-bottom: 7px;
        }
        .AlertStatus1 p:last-child,
        .AlertStatus2 p:last-child,
        .AlertStatus3 p:last-child,
        .AlertStatus4 p:last-child {
            font-size: 0.800rem;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: $color_3;
            margin-bottom: 0px;
            width: 170px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include devices(laptop_xl) {
                width: 150px;
            }
            @include devices(laptop_lg) {
                width: 141px;
            }
            @include devices(laptop_md) {
                width: 122px;
            }
            @include devices(laptop_sm) {
                width: 113px;
            }
        }
        .processFlow {
            width: 100%;
            height: 22.2rem;
            padding: 1.094rem 1.063rem 1.031rem;
            border-radius: 13px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
            background-color: $background-color_8;
        }
        .AlertActivityLog {
            width: 100%;
            height: auto;
            padding: 15px;
            border-radius: 12px;
            box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
            background-color: $background-color_8;
        }
        .related-alerts-head {
            height: 2rem;
            margin: 14px 0 10px 0px;
            font-size: 1.5rem;
            @include fontStyle;
            font-weight: bold;
            color: $color_3;
        }
        .activityLogTab {
            width: 100%;
            height: 2.375rem;
            margin: 0 0 1.125rem 0.094rem;
            padding: 0.313rem 24.938rem 0.313rem 0.313rem;
            border-radius: 19px;
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px #748b94;
            background-color: $background-color_1;
            @include devices(laptop_xs) {
                padding: 0.313rem 22.938rem 0.313rem 0.313rem;
            }
        }
        .activity-desc {
           /*  width: 68% !important; */
            margin: 0 0 0 15px;
            font-size: 0.875rem;
            @include fontStyle;
            color: $color_3;
            display: inline-block;
        }
        .alert-label {
            width: 4.938rem;
            height: 1.188rem;
            margin: 0 0.625rem 0 0;
            font-size: 0.875rem;
            @include fontStyle;
            font-weight: 600;
            color: $color_3;
        }        
        .AttachEvidencesButton {
            width: auto;
            height: 31px;
            border-radius: 15px;
            border: 1px solid #0e6689;
            background-color: $background-color_1;
            margin-top: 0px;
            margin-right: 10px !important;
        }
        .AttachEvidencesButton p {
            font-size: 0.875rem;
            font-weight: 600;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $color_3;
            margin: 0px;
        }
        .horizontalLine {
            width: 100%;
            height: 1px;
            margin: 1.45rem 0 1.45rem 0;
            opacity: 0.24;
            border: solid 1px #7a7c8a;
            background-color: #d8d8d8;
        }
        
        .activityLogBtn {
            height: 1.75rem;
            padding: 0.375rem 1.188rem 0.375rem 1.313rem;
            border-radius: 15.5px;
            /* box-shadow: 0 12px 16px 0 rgba(44, 121, 152, 0.3); */
            /* background-image: linear-gradient(244deg, #4ca0c2 100%, #027aaa -5%); */
            /* background-image: linear-gradient(244deg, #0f62acc9 26%, #0f62ac); */
            background-color: #0b70d1;
            font-size: 0.75rem;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #fff;
            border: none;
        }                
        .activity-row {
            margin: 0 auto;
            list-style-type: none;
        }
        .activity-type {
            width: 7% !important;
            height: 1.375rem;
            /* margin: 1.125rem 0 0.844rem 0; */
            object-fit: contain;
            display: inline-block;
        }
        .activity-time {
           /*  width: 22% !important; */
            height: 1rem;
            /* margin: 1.375rem 0 0.969rem 0; */
            font-size: 0.75rem;
            @include fontStyle;
            text-align: right;
            color: #748b94;
            display: inline-block;
        }
        .alert-icon-bg {
            width: 1.5rem;
            height: 1.5rem;
            margin: 13px 0 13px 0;
            padding: 0 auto;
            background-color: $background-color_1;
            border-radius: 30%;
            text-align: center;
            >img {
                width: 0.813rem;
                height: 0.688rem;
                object-fit: contain;
            }
        }
        .alert-title {
            width: 13.438rem;
            height: 1.688rem;
            margin: 0.5rem 1.875rem 1.656rem 0.563rem;
            font-size: 1.25rem;
            @include fontStyle;
            font-weight: bold;
            color: #0e6689;
        }
        .alert-icon {
            display: inline-block;
        }
        .alert-label-value {
            display: inline-block;
        }
        .alert-value {
            width: 4.563rem;
            height: 1.188rem;
            margin: 0 1.031rem 0 0.625rem;
            font-size: 0.875rem;
            @include fontStyle;
            font-weight: 600;
            color: $color_3;
        }
        
        .fullscreen-btn {
            @include floatRight;
            cursor: pointer;
        }

        .view-chatroom-btn {
            width: auto;
            height: 2rem;
            border-radius: 25px;
            border: none;
            /* box-shadow: 0 12px 16px 0 rgb(44 121 152 / 30%); */
            /* background-image: linear-gradient(212deg, #0f688b 10%, #1a5974); */
            /* margin-top: 5px; */
            padding-left: 30px;
            padding-right: 30px;
            @include floatRight;
            background-color: #0b70d1;
        }
        .view-chatroom-btn>span {
            width: 6.125rem;
            height: 1.188rem;
            font-size: 0.875rem;
            @include fontStyle;
            text-align: center;
            color: #fff;
            border: none;
        }
        .alertkpiIcon {
            top: 22px;
            right: 55px;
        }  
        .alertTable {
            display: inline-block;
            width: 100%;
            border-radius: 12px;
            box-shadow: $box-shadow_1;
            padding: 1.156rem 0 1.031rem 0;
            height: 100%;
        }    
        .alertTableList {
            float: left;
            width: 100%;
            padding: 0 1.594rem 0 1.25rem;
            p{
                img {
                    padding: 0 10px 0 10px;
                }
            }
        }       

        .DocText {
            font-size: 0.75rem;
            @include fontStyle;
            font-weight: 600;
            text-align: center;
            color: #4a595f;
            margin-bottom: 0px;
            margin-top: 5px;
        }
        .DocBox {
            width: 23%;
            height: 3.438rem;
            display: inline-block;
            border-radius: 8px;
            background-color: #FCF4E3;
            text-align: center;
            padding-top: 5px;
            margin-right: 9px;
            cursor: pointer;
        }
        .ImageBox {
            width: 23%;
            height: 3.438rem;
            display: inline-block;
            border-radius: 8px;
            background-color: #DBEDEA;
            text-align: center;
            padding-top: 5px;
            margin-right: 9px;
            cursor: pointer;
        }

        .VideosBox {
            width: 23%;
            height: 3.438rem;
            display: inline-block;
            border-radius: 8px;
            /* background-color: #E6E4FC; */
            background-color: #ebe8e8;
            text-align: center;
            padding-top: 5px;
            margin-right: 9px;
            cursor: pointer;
            /* pointer-events: none; */
            /* to remove at the time of implementation */
        }
        .VoiceBox {
            width: 23%;
            height: 3.438rem;
            display: inline-block;
            border-radius: 8px;
            background-color: #FADDDE;
            text-align: center;
            padding-top: 5px;
            margin-right: 9px;
            cursor: pointer;
        }
        .UploadText {
            font-size: 0.875rem;
            @include fontStyle;
            font-weight: 600;
            color: $color_12;
            margin-bottom: 10px;
            display: inline-block;
        }
        .UploadTextarea {
            width: 100%;
            border-radius: 8px;
            background-color: $background-color_8;
            border: none;
            padding-left: 10px;
            padding-right: 10px;
            font-size: 13px;
            text-align: left;
            resize: none;
            color: $color_3;
            &:focus {
                outline: none;
            }
        }
        .GreenText {
            font-size: 0.75rem;
            @include fontStyle;
            text-align: center;
            color: #2cb29e;
            padding: 8px;
            border-radius: 14px;
            background-color: rgba(44, 178, 158, 0.16);
            margin-left: 5px;
        }
        .AttachmentsDiv {
            height: 11.688rem;
            margin: 0px;
            padding-top: 15px;
            padding-bottom: 15px;
            border: 1px solid #707070;
            background-color: $background-color_8;
            border-radius: 15px;
        }
        .mainContentList {
            padding: 15px;
            height: 10rem;
            overflow: auto;
        }
        .ListAttach {
            @include flexSpaceBetween;
            margin-bottom: 15px;
        }
        .ListAttchP {
            margin-bottom: 0px;
            font-size: 0.875rem;
            @include fontStyle;
            font-weight: 600;
            color: $color_3;
            display: inline-block;
        }
        .AttachEvidencesBigButton {
            width: auto;
            height: 2rem;
            border-radius: 25px;
            border: none;
            /* box-shadow: 0 12px 16px 0 rgb(44 121 152 / 30%); */
            /* background-image: linear-gradient(212deg, #0f688b 10%, #1a5974); */
            background-color: $background-color_2;
            margin-top: 5px;
            padding-left: 30px;
            padding-right: 30px;
            float: right;
            p {
                font-size: 0.875rem;
                @include fontStyle;
                text-align: center;
                color: #fff;
                margin-bottom: 0px;
            }        
        }
        .activityUpdateModel {
            .modal-dialog {
                margin-right: 0;
                position: relative;
                top: -8px;
                right: -8px;
            }
        }
        .icon-hover {
            cursor: pointer;
        }
        .Blink {
            animation: blinker 1.5s cubic-bezier(.5, 0, 1, 1) infinite alternate;
        }        
        @keyframes blinker {
            from {
                opacity: 1;
            }

            to {
                opacity: 0;
            }
        }        
        .uploadTxt {
            font-size: 11px;
            color: #8e8f8f;
            height: 20px;
            margin-top: 0.4rem !important;
        }
        .upload-options {
            display: inline-flex;
            width: 100%;
            height: 4rem;
        }        
        .evidence-item {
            cursor: pointer;
            display: flex;
            align-items: center;
        }
        .attach-file-div {
            display: inline-flex;
            align-items: baseline;
            justify-content: space-between;
            width: 100%;
        }
        
        .selected-file-text {
            /* width: 90%; */
            width: 20rem;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }                
        .no-report-found {
            text-align: center;
            height: calc(102vh - 291px);
            transform: translate(0%, 0%);
            font-size: 1.5rem;
            /* padding: 180px 0; */
        }
        .no-report-found-background-image {
            background-image: url("../../../src/assets/images/cameraNosearchResultsFound.png");
            background-position: center;
            background-repeat: no-repeat;
            width: 500px;
            height: 500px;
            margin: 0 auto;
            background-size: contain;
        }
        .no-camera-found {
            text-align: center;
            height: calc(100vh - 200px);
            transform: translate(0%, 0%);
            font-size: 1.5rem;
        }
        .no-camera-found-text {
            /* width: 600px; */
            /* margin: 11px auto 0; */
            /* margin: 124px auto 0; */
            font-size: 3rem;
            font-weight: 500;
            text-align: center;
            color: gray;
        }
        .cursor-ptr {
            cursor: pointer;
        }
        .cursor-not-allowed {
            cursor: not-allowed;
        }
        .player-responsive {
            width: 100% !important;
            height: 250px !important;
            padding: 0.2rem 0.2rem;
        }        
        .alrtevidencemodalheader {
            padding: 1rem 1rem 0rem 1rem;
            border-bottom: 0px !important;
            color: $color_3;
        }
        .AlertActivityReportTable {
            padding-bottom: 10px;
        }
        
        .related-site-alerts-container {
            padding-left: 1rem !important;
        }        
        .reports-dateRange-and-searchBox {
            display: flex;
            float: right;
        }
        /* New design for Alert grid hover start */
        .MainAlertsFridDiv{
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-auto-rows: 274px;
            grid-gap: 7px;
            @include devices(laptop_xl) {
                grid-template-columns: repeat(6, 1fr);
            }  	
            @include devices(laptop_md) {
                grid-template-columns: repeat(5, 1fr);
            }  	
        }
        .GridMain {
            /* position: absolute;
            top: 50%;
            left: 50%; */
            /* -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0); */
            /* transform: translateX(-50%) translateY(-50%) translateZ(0); */
            /* width: 370px; */
           /*  background-color: #fff; */
           width: auto;
           height: 274px;
           margin: 0;
           padding: 0.4rem 0 0;
           border-radius: 7px 7px 0px 0px;
           box-shadow: $box-shadow_1;
           background-color: $background-color_9;
           text-align: center;
           float: left;
           overflow: hidden;
           -webkit-transition: box-shadow 0.5s;
           transition: box-shadow 0.5s;
           &:hover {
                .GridBgImg {
                    height: 73px;
                    img {
                        opacity: 0.6;
                        -webkit-transform: scale(1.2);
                        transform: scale(1.2);
                    }
                }
            }
        }

        .GridBgImg {
            height: 115px;
            overflow: hidden;
            background-color: #000;
            -webkit-transition: height 0.5s;
            transition: height 0.5s;
            position: relative;
            img {
                width: 100%;
                display: block;
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
            }
        }
        .GridBgImgChat {
            // height: 115px;
            overflow: hidden;
            background-color: #000;
            -webkit-transition: height 0.5s;
            transition: height 0.5s;
            position: relative;
            img {
                width: 100%;
                display: block;
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
                -webkit-transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, -webkit-transform 0.5s;
                transition: opacity 0.5s, transform 0.5s;
                transition: opacity 0.5s, transform 0.5s, -webkit-transform 0.5s;
            }
        }
        .TitlesOfGrid {
            position: relative;
            /* height: 185px; */
            /* padding: 10px; */
            -webkit-transition: height 0.5s;
            transition: height 0.5s;
            margin-left: 10px;
            margin-right: 10px;
            p {
                cursor: default;
            }
        }
        .AlertTypeText {        
            height: 22px;        
            width: 100%;        
            background-color: rgba(0, 0, 0, 0.6);        
            position: absolute;        
            bottom: 0;        
            padding-left: 7px;
        }

        /* .GridMain:hover .TitlesOfGrid {
            height: 170px;
        } */

      /*   .ButtonsDivAlert {
            opacity: 0;
            -webkit-transform: translateY(45px);
            transform: translateY(45px);
            -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
            transition: opacity 0.3s, -webkit-transform 0.3s;
            transition: opacity 0.3s, transform 0.3s;
            transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
            -webkit-transition-delay: 0s;
            transition-delay: 0s;
        }

        .GridMain:hover .ButtonsDivAlert {
            opacity: 1;
            -webkit-transform: translateY(0px);
            transform: translateY(0px);
             -webkit-transition-delay: 0.2s;
            transition-delay: 0.2s; 
        } */
        .GridAlertTypeImage {
            width: 100%;        
            height: 100%;        
        }     
        .AlertTypeText {        
            height: 22px;        
            width: 100%;        
            background-color: rgba(0, 0, 0, 0.6);        
            position: absolute;        
            bottom: 0;        
            padding-left: 7px;
        }
        .TitlesOfGrid {
            p {
                cursor: default;
            }    
        }
        .MB2 {
            margin-bottom: 2px !important;
        }
        .MB5 {
            margin-bottom: 5px !important;
        }
        .extreme_colour {
            color: #dd2121;
        }
        .high_colour{
            color:#d06841;
        }
        .medium_colour{
            color: #daa926 ;
        }
        .low_colour{
            color: #83ba2b; 
        }

        .verticalLine{
            margin-left: 5px;
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: column;
                li {
                    &.step {
                        .LogSteps {
                            display: flex;
                            align-items: center;
                            &:before {
                                content: '';
                                width: 15px;
                                height: 15px;
                                display: inline-block;
                                border-radius: 50%;
                                margin-right: 8px;
                                background: #ddd;
                            }
                        }
                        &.completed {
                            .LogSteps {
                                &:before {
                                    content: '';
                                    color: white;
                                    font-size: .8em;
                                    text-align: center;
                                    background: #0b70d1;
                                }
                            }
                        }            
                        &:not(:last-child):after {
                            height: 55px;
                            width: 2px;
                            content: '';
                            display: block;
                            background-color: #ddd;
                            margin-left: 7px;
                            margin-top: -5px;
                            margin-bottom: -5px;
                        }
                        &.completed {
                            &:not(:last-child):after {
                                background-color: #0b70d1;
                            }
                        }
                    }
                }
            }
        }
        .No-Activitylog-Present {
            width: auto;
            margin: 0 0 3px;
            font-family: Segoe UI;
            font-size: 20px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 0.75;
            letter-spacing: normal;
            text-align: left;
            color: #000;
            @include devices(laptop_xl) {
                font-size: 1.2rem;
            }
            @include devices(laptop_lg) {
                font-size: 1.1rem;
            }
            @include devices(laptop_md) {
                font-size: 0.96rem;
            }
            @include devices(laptop_sm) {
                font-size: 0.8rem;
            }
        }

        .alertevidenceModel{
            .modal-content {
                background-color: $background-color_10;
                .modal-header {
                    .modal-title{
                        color: $color_3;
                    }
                }
            }
        }
        .headerOperateByDiv {
            // width: 25%;
            padding: 10px 3px 10px;
        }
        .newListofAlert {
            // height: 60px; */
            border-bottom: 1px solid rgb(72, 72, 72);
            padding: 0px 10px;
            background-color: #070612;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
        }
        .NewOperateName {
            width: 100px;
            height: 25px;
            background: #FFF7E2 0% 0% no-repeat padding-box;
            border-radius: 25px;
            opacity: 1;
        }
        .hgtWorkFlowDiv {
            height: 777px;
            overflow: auto;
            @include devices(laptop_xl) {
                height: 750px;
            }
            @include devices(laptop_lg) {
                height: 560px;
            }
            @include devices(laptop_md) {
                height: 600px;
            }
            @include devices(laptop_sm) {
                height: 465px;
            }
            @include devices(laptop_xs) {
                height: calc(100vh - 165px);
            }
        }
        .default-node,
        .react-flow__node-input {
            color: #BABABA;
        }
        .UploadFilesDiv {
            @include devices(laptop_sm) {
                height: 530px;
                overflow: auto;
                padding-right: 5px;
            }
        }  
        // .rs-calendar-time-dropdown,
        // .rs-calendar-time-dropdown-column-title,
        // .rs-calendar-btn-close {
        //     background-color: #070612 !important;
        // }    
        .EmailDownloadBox {
            width: 100% !important;
            border: 1px solid #84a5c4;
            margin-top: 15px;
            float: left;
        }
        .successMsg {
            display: flex;
            background: #E1F8DA;
            align-items: center;
            padding-left: 10px;
            height: 50px;
        }
        .chatFlowWebHeaderTop {
            background-color: #1F1A24;
        }
        .chatFlowWebHeaderAlertText {
            color: #ffffff;
        }
        .sidebarLabel {
            font-weight: 600;
            font-size: 15px;
        }

        .ListGridActiveBtn {
            height: 30px;
            width: 30px;
            background-color: $background-color_1;
            border-radius: 8px;
            margin-left: 5px;
        }
        .ListGridBtn {
            height: 30px;
            width: 35px;
            background-color: #0b70d1;
            border-radius: 8px;
            margin-left: 5px;
        }
        .MainCameraAlertDiv{
            float: left;
            width: 100%;
            height: calc(100vh - 160px);
        }
        .AlertDropdownHeaderDiv{
            display: flex;
            align-items: center;
            padding: 10px 15px 0px;
        }
        .InputSelectionDropdown{
            width: 21%;
            margin-right: 16px;
            .inputTypeLabelContainer .formFieldLabels {
              position: absolute; 
              font-size: 14px;
              font-weight: normal;
              // color: #5f6368;
              color: #959597;
              background-color: #fff;
              padding: 0px 4px;
              top: -10px;
              left: 8px;
              margin: 0px !important;
              line-height: 16px;
              @include devices(laptop_lg) {
                font-size: 11px;
              }
              @include devices(laptop_md) {
                font-size: 11px;
              }
              @include devices(laptop_sm) {
                font-size: 10px;
              }
        }
        .inputType {
          @include devices(laptop_lg) {
            font-size: 13px;
          }
          @include devices(laptop_md) {
            font-size: 12px;
          }
          @include devices(laptop_sm) {
            font-size: 10px;
          }
        }
        }
        .AlertCameraTileDiv{
          height: 160px;
          width: 23%;
          background: #F0F0F0;
          border-radius: 5px;
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
          margin: 0px 8px 15px;
          float: left;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          p{
            font-size: 13px;
            margin-bottom: 0px;
            color: #535353;
          }
          @include devices(laptop_xs) {
            display: block;
          }
          @include devices(laptop_xl) {
            width: 23%;
          }
          @include devices(laptop_lg) {
            width: 23%;
          }
          @include devices(laptop_md) {
            width: 31%;
          }
          @include devices(laptop_sm) {
            width: 30.5%;
          }
          
        }
        .AlertCameraGridList{
          overflow: auto;
          scroll-behavior: smooth;
          padding: 0px 10px;
          height: calc(100vh - 270px);
          // @include devices(laptop_xl) {
          //   height: 675px;
          // }
          // @include devices(laptop_lg) {
          //   height: 525px;
          // }
          // @include devices(laptop_md) {
          //   height: 528px;
          // }
          // @include devices(laptop_sm) {
          //   height: 400px;
          // }
          // @include devices(laptop_xs) {
          //   height: 660px;
          // }
        }
        .alertMosaicSelection{
            margin-top: 0px !important;
            height: 35px;
            width: 90px;
            .inputTypeLabelContainer{
                height: 35px;
                background: #E9F2FF;
                border: 1px solid #0D70D1;
            }
        }
        .AlertCameraTileFilterBorderDiv{
          background-color: $background-color_1;
          border-radius: 13px;
          box-shadow: 10px 10px 15px #00000069;
          height: 760px;
          width: 20%;
          position: absolute;
        }
        .MainCameraAlertDiv{
          float: left;
          width: 100%;
          height: calc(100vh - 160px);
        }
        .CameragridAssociateDiv{
        //   height: 300px;
        //   border: 1px solid #d3d3d3;
        margin: 10px;
          @include devices(laptop_xl) {
           /*  height: 250px; */
          }
          @include devices(laptop_lg) {
            /* height: 235px; */
          }
          @include devices(laptop_md) {
            height: 205px;
          }
          @include devices(laptop_sm) {
            height: 180px;
          }
          @include devices(laptop_xs) {
            height: 240px;
          }
          :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 5px;
        }
        }
        .CameragridChatBotDiv{
         /*  height: 400px; */
        }
        .AlertCameraChatbotHgtDiv{
          // height: 350px;
          height: calc(100vh - 610px);
          overflow: auto;
          @include devices(laptop_xl) {
            height: 385px;
          }
          @include devices(laptop_lg) {
            height: 250px;
          }
          @include devices(laptop_md) {
            height: 284px;
          }
          @include devices(laptop_sm) {
            height: 190px;
          }
          @include devices(laptop_xs) {
            height: 378px;
          }
        }
        .AlertCameraChatbotHeigthDiv {
            height: calc(100vh - 530px);
            overflow: auto;
        }


        /* Style of triangle of severity option start */
    .triangle-up {
        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-bottom: 20px solid #eaeaea;
        text-align: end;
        position: absolute;
        top: 35px;
        right: 7px;
    }
    /* Style of triangle of severity option end */

    .TruncateText{
      color: #787878 !important;
      margin-left: 3px !important;
      font-weight: normal !important;
      font-size: 12px !important;
      margin-bottom: 0px !important;
      float: left;
      width: 140px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .scrollXS{
      @include devices(laptop_sm) {
        overflow: scroll;
        height: calc(100vh - 350px);
      }

      @include devices(laptop_xs) {
        overflow: scroll;
        height: calc(100vh - 350px);
      }
      
      .col-lg-8{
        @include devices(laptop_sm) {
          max-width: 100%;
          width: 100%;
          float: left;
          flex: auto;
        } 
        @include devices(laptop_xs) {
          max-width: 100%;
          width: 100%;
          float: left;
          flex: auto;
        }        
      }
      .col-lg-4{
        @include devices(laptop_sm) {
          max-width: 100%;
          flex: auto;
        } 
        @include devices(laptop_xs) {
          max-width: 100%;
          flex: auto;
        }  
      }
      .ChatScroll{
        height: calc(100vh - 415px);

        padding: 5px;

       /*  @include devices(laptop_xl) {

          height: calc(100vh - 460px);

        }*/

        @include devices(laptop_lg) {

          height:  calc(100vh - 460px);

        } 
        @include devices(laptop_sm) {
          height: 210px;
        } 
        @include devices(laptop_xs) {
          height: 300px;
        } 
      }

    }

    .InputSelectionDropdownBtn {
      display: flex;
    }

    .rowPadding {
        padding: 0px 10px;
    }
    .rowSpacing {
        padding: 0px 30px;
        width: 100%;
        margin: 15px 0px;
    }
    .HeaderListbtn {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .LeftContainer{
        background-color: $background-color_1;
        border-radius: 13px;
        box-shadow: $box-shadow_1;
        // height: 100%;
        height: calc(100vh - 183px);

        /* @include devices(laptop_xl) {

          height: 765px;

        }

        @include devices(laptop_lg) {

          height: 615px;

        }

        @include devices(laptop_sm) {

          height: 490px;

        }

        @include devices(laptop_xs) {

          height: 740px;

        } */
    }
    .RightContainer {
        background-color: $background-color_1;
        border-radius: 13px;
        box-shadow: $box-shadow_1;
        height: calc(100vh - 183px);
       /*  @include devices(laptop_xl) {
          height: 760px;
        }
        @include devices(laptop_lg) {
          height: 612px;
        }
        @include devices(laptop_sm) {
          height: 490px;
        }
        @include devices(laptop_xs) {
          height: 740px;
        } */
    }
    .AlertFilterHeaderDiv{
        /* height: 100px; */
        background-color: $background-color_2;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
    }
    .AlterfilterText{
        margin-bottom: 0px;
        color: $color_3;
        padding: 10px;
        span{
          font-weight: bold;
          margin-left: 3px;
        }
    }
    .AlertFilterHgtDiv{
        height: calc(100vh - 285px);
        padding: 0px 10%;
        overflow: auto;
        .rs-picker-toggle-wrapper{
            width: 100%;
        }
        .rs-picker-toggle-textbox, .rs-picker-default .rs-picker-toggle{
            border: none;
            background-color: transparent !important;
        }
        /* @include devices(laptop_xl) {
          height: 665px;
        }
        @include devices(laptop_lg) {
          height: 510px;
        }
        @include devices(laptop_sm) {
          height: 375px;
        }
        @include devices(laptop_xs) {
          height: 630px;
        } */
        
    }
    .AlertFilterDataLabels{
        margin-bottom: 0px;
        color: #656363;
        font-size: 13px;
    }

    .AlertStatusDetail {
      width: 100%;
      height: 4.2rem;
      padding: 0.7rem;
      margin: 0px 5px;
      @include devices(laptop_lg) {
        height: 5.2rem;
      }
      .AlertStatus{
        @include devices(laptop_lg) {
          display: block;
        }
        @include devices(laptop_sm) {
          display: block;
        }
      }

      hr{
        @include devices(laptop_lg) {
          float: left;
          width: 100%;
          margin-bottom: 5px;
        }
        @include devices(laptop_sm) {
          float: left;
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .AlertStatus5 {
        width: 38%;
        padding-right: 10px;
        @include devices(laptop_xl) {
          float: left;
          width: 29%;
        }
        @include devices(laptop_lg) {
          float: left;
          width: 33%;
        }
        @include devices(laptop_sm) {
          float: left;
          width: 33%;
        }
        p {
          font-size: 13px;
          font-stretch: normal;
          font-weight: bold;
          line-height: 14px;
          font-style: normal;
          color: #6D6D6D;
          letter-spacing: normal;
          margin-bottom: 10px;
          span{
            color: #787878;
            margin-left: 3px;
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
    .ChatCommentInputDiv {
        width: 86%;
        float: left;
        @include devices(laptop_xl) {
          width: 85%;
        }
        @include devices(laptop_lg) {
          width: 80%;
        }
        @include devices(laptop_sm) {
          width: 79%;
        }
        @include devices(laptop_xs) {
          width: 84%;
        }
      }
      .GridChatCommentInputDiv {
        width: 76%;
        float: left;
        @include devices(laptop_xl) {
          width: 72%;
        }
        @include devices(laptop_lg) {
          width: 70%;
        }
        @include devices(laptop_md) {
          width: 66%;
        }
        @include devices(laptop_sm) {
          width: 63%;
        }
        @include devices(laptop_xs) {
          width: 58%;
        }
      }
      .AlertSendAttachMicButtons {
        display: flex;
        align-items: center;
        float: right;
        background-color: #fff;
        margin-top: 0px;
        // padding-right: 10px;
      }
      .chatbotCommentInput {
        border: 1px solid #ccc;
        height: 40px;
        border-radius: 7px;
        padding: 5px;
        // width: 100%;
        margin: 4px 5px 10px 5px;
      }
      .chatbotInput {
        border-radius: 8px;
        background-color: transparent;
        border: none;
        width: 100%;
        font-size: 0.75rem;
        padding-left: 10px;
        &::placeholder {
          font-family: Arial;
          font-size: 13px;
          line-height: 1.15;
          color: #B4B4B4;
          @include fontStyle;
        }
        &:focus {
          outline: none;
        }
      }
      .MikeBtn {
        height: 30px;
        width: 35px;
        background-color: $background-color_2;
        border-radius: 8px;
        margin-left: 5px;
    }
    
    .m-backtotop {
        @include transition(all 0.3s ease-in-out);
        position: fixed;
        bottom: 40px;
        right: 40px;
        width: 60px;
        height: 60px;
        border-radius: 35px;
        text-align: center;
        border: 1px solid #0b70d1;
        overflow: hidden;
        background-color:#fff;
        cursor: pointer;
        box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
    
        &.active {
          bottom: 15px;
          opacity: 1;
        }
    
        & > div {
          @include transition(all 0.3s ease-in-out);
          &.arrow {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            opacity: 1;
            color: #0b70d1;
            span {
                font-size: 16px;
                margin: 0;
                padding: 0;
                font-weight: 600;
            }
          }
          &.text {
            @include font-size(0.5, 1);
            text-transform: uppercase;
            font-weight: 900;
            font-family: sans-serif;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(50%) translateX(-50%);
            opacity: 0;
            margin-top: 1px;
          }
        }
        
        .camerafilter3x3 {
            grid-gap: 8px;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(6, 1fr);
            /* grid-template-rows: 1fr 1fr 1fr; 
            grid-template-columns: 1fr 1fr 1fr;  */
            // height: 78.8vh;
            height: calc(100vh - 80px);
            overflow: hidden;
            padding: 10px;

            /* @include devices(laptop_lg) {
                grid-template-rows: 1fr 1fr 1fr; 
                grid-template-columns: 1fr 1fr 1fr; 
            }
            @include devices(laptop_md) {
                grid-template-rows: 1fr 1fr 1fr; 
                grid-template-columns: 1fr 1fr 1fr; 
            }
            @include devices(laptop_sm) {
                grid-template-rows: 1fr 1fr 1fr; 
                grid-template-columns: 1fr 1fr 1fr; 
            } */
            .HeightChildDiv {
                &:nth-child(1) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(2) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(3) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(4) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(5) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(6) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(7) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(8) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                &:nth-child(9) {
                    grid-column: auto / span 2;
                    grid-row: span 2;
                }
                .hoverDetails {
                    height: 100%;
                    position: relative;
                    &:hover .mosaicButtonsMainDiv {
                        display: flex;
                        bottom: 1px;
                    }
                }
            }
            .alertInprogressDiv {
                img {
                    @include devices(laptop_lg) {
                        height: 90px;
                    }
                    @include devices(laptop_md) {
                        height: 75px;
                    }
                    @include devices(laptop_sm) {
                        height: 65px;
                    }
                }
            }
          }
          .camerafilter4x4 {
            grid-gap: 8px;
            display: grid;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: repeat(6, 1fr);
            height: calc(100vh - 9%);
           /*  grid-template-rows: 1fr 1fr 1fr 1fr; 
            grid-template-columns: 1fr 1fr 1fr 1fr;  */
            overflow: hidden;
            padding: 10px;
           /*  @include devices(laptop_lg) {
                grid-template-rows: 1fr 1fr 1fr 1fr; 
                grid-template-columns: 1fr 1fr 1fr 1fr; 
            }
            @include devices(laptop_md) {
                grid-template-rows: 1fr 1fr 1fr 1fr; 
                grid-template-columns: 1fr 1fr 1fr 1fr; 
            }
            @include devices(laptop_sm) {
                grid-template-rows: 1fr 1fr 1fr 1fr; 
                grid-template-columns: 1fr 1fr 1fr 1fr; 
            } */
            .HeightChildDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
                .hoverDetails {
                    height: 100%;
                    position: relative;
                    &:hover .mosaicButtonsMainDiv {
                        display: flex;
                        bottom: 1px;
                    }
                }
            }
            .alertInprogressDiv {
                img {
                    @include devices(laptop_lg) {
                        height: 90px;
                    }
                    @include devices(laptop_md) {
                        height: 75px;
                    }
                    @include devices(laptop_sm) {
                        height: 65px;
                    }
                }
            }
          }

          .camerafilter3x3 {
            .alertInprogressDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
                background: #F4F4F4 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 10px #00000033;
                border: 1px solid #E3E3E3;
                border-radius: 5px;
            }
          }
          .camerafilter4x4 {
            .alertInprogressDiv {
                grid-column: auto / span 2;
                grid-row: span 2;
                background: #F4F4F4 0% 0% no-repeat padding-box;
                box-shadow: 0px 0px 10px #00000033;
                border: 1px solid #E3E3E3;
                border-radius: 5px;
            }
        }

          .alertChatflowContainer {
            background-color: #fff;
            border-radius: 13px;
            box-shadow: rgb(112, 111, 111) 0px 0px 5px 0px inset, 0 0 6px 0 rgba(0, 0, 0, 0.3);
            width: 26%;
            min-width: 25%;
            margin-top: 10px;            
            display: flex;
            flex-direction: column;
            @include devices(laptop_lg) {
                margin-top: 0px;
            }
            @include devices(laptop_sm) {
                margin-top: 0px;
            } 
            .multi-chatbot-container-div{
               /* height: 100%; */
               margin: 10px;
               overflow: auto;
               padding-right: 0px;
               scroll-behavior: smooth;
               flex: 1 1 auto;
               overflow-y: auto;
                /* @include devices(laptop_xl) {
                    height: 570px;
                }
                @include devices(laptop_lg) {
                    height: 450px;
                }
                @include devices(laptop_sm) {
        
                } */
                /* .react-functional-chatbot-main-div{
                    height:calc(100vh - 510px);
                    @include devices(laptop_sm) {
                        height: calc(100vh - 440px);
                    }
                } */
            }
          }
          .AlertGridChatCommentInputDiv {
            width: 70%;
            float: left;
            @include devices(laptop_xl) {
              width: 72%;
            }
            @include devices(laptop_lg) {
              width: 70%;
            }
            @include devices(laptop_md) {
              width: 66%;
            }
            @include devices(laptop_sm) {
              width: 63%;
            }
            @include devices(laptop_xs) {
              width: 58%;
            }
          }
          .alertCameraHeadingCss {
            position: absolute;
            top: 0px;
            padding: 5px;
            background-color: rgba(0, 0, 0, 0.6);
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
          .alertCameraDetailCss {
            position: absolute;
            top: 36px;
            padding: 5px;
            background-color: rgba(0, 0, 0, 0.6);
            width: 100%;
            height: calc(100% - 37px);
          }
          .AlertCameraDetailsText {
            height: 16px;
            font-size: 12px;
            font-weight: normal;
            text-align: left;
            color: #FFFFFF;
            /* width: 200px; */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            line-height: 16px;
          }
          
        // &:hover {
        //   transform: scale(1.1);
        //         bottom:20px;
        //   cursor: pointer;
        //   background: darken(#222, 15%);
        //         box-shadow: 0 10px 5px rgba(0, 0, 0, 0.1);
    
        //   & > div {
        //     &.arrow {
        //       transform: translateY(-150%) translateX(-50%);
        //       opacity: 0;
        //     }
        //     &.text {
        //       transform: translateY(-50%) translateX(-50%);
        //       opacity: 1;
        //     }
        //   }
        // }
      }
      .FilterOpenAlertsText{
        display: flex;
        align-items: center;
        height: 35px;
        margin: 0px 5px 9px 5px !important;
      }
      .deleteModalMainClass {
        .SiteLeftContainer {
          width: 300px !important;
          margin-bottom: 5px;
          flex-shrink: 0;
          .SearchViewInput1 {
              width: 90% !important;
          }
    }
    } 
    .main-container {
        display: flex;
        overflow-x: auto;
        max-width: 1410px;
        margin: 0 auto; /* Center container */
    }
      .infoFilterHeader {
        padding: 0px 10px;
        background-color: #dbe6fc;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        height: 40px;
        display: flex;
        align-items: center;
        p {
            font-weight: 500;
            margin-bottom: 0px;
        }
      }
      .SavedFilterDiv {
        margin: 0px 5px 9px 5px !important;
        border-radius: 10px;
        .dropdown-menu.show{
            width: 250px;
            transform: translate(0px, 37px) !important;
          }
          .dropdown{
            min-width: 200px;
            width: auto;
            height: 35px;
            background: transparent; 
            border-radius: 10px;
            border: 1px solid #464646;
          }
          .dropdown-toggle{
            height: 33px;
            background: transparent;
            width: 100%;
            text-align: left;
            font-size: 13px;
            border-radius: 10px;
            margin: 0px;
            color: #bababa;
            font-family: Arial;
            border: 0;
            padding-right: 45px;
          }
          .btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show>.btn-light.dropdown-toggle {
            color: #bababa;
            background-color: transparent;
            border-color: transparent;
        }
        .btn-light:hover{
            border-color: transparent;
        }
        .dropdown-item:active{
            background-color: transparent;
            color: $color_3;
        }
        .dropdown-item {
            text-decoration: none !important;
            text-align: left !important;
            display: unset !important;
            font-size: 13px;
        }
        .SavedFilterOption {
            display: flex;
            justify-content: space-between;
            padding: 5px 10px;
            &:hover .SaveFilterOtionHover {
                display: block;
            }
        }
        .SaveFilterOtionHover {
            display: none;
        }
      }
      .SaveFilterInfoList {
        height: 45vh;
		margin: 10px;
		overflow: auto;
		padding-right: 8px;
		scroll-behavior: smooth;
        .ListofText {
            font-size: 0.875rem;
            color: $color_12;
            margin-bottom: 0px;
            // @include fontStyle;
            font-stretch: normal;
            font-style: normal;
            line-height: 22px;
            letter-spacing: normal;
            text-align: left;
            font-weight: 500;
    
            @include devices(laptop_sm) {
                font-size: 0.7rem;
            }
    
            @include devices(laptop_md) {
                font-size: 0.8rem;
            }
        }

		@include devices(laptop_xxl) {
			height: 65vh;
		}

		@include devices(laptop_xl) {
			height: 69vh;
		}

		@include devices(laptop_lg) {
			height: 63vh;
		}

		@include devices(laptop_md) {
			height: 63vh;
		}

		@include devices(laptop_sm) {
			height: 55vh;
		}

		@include devices(laptop_xs) {
			height: calc(100vh - 285px);
		}
      }
      .SavedFilterCancelBtn{
        background-color: transparent;
        position: absolute;
        right: 17px;
        top: 2px;
    }
    .newAlertChatDiv{
        height: 595px !important;
      }

      .AlertGridHgtDiv{
        padding: 15px;
        box-shadow: none !important;
        height: 100vh;
        display: flex;
        flex-direction: column;
        border-radius: 0 !important;
        flex-direction: column;
        .alertChatflowContainer{
            margin-top: 0;
        }
        .camerafilter3x3, .camerafilter4x4{
            height: 100%; 
            padding: 0px 10px;
        }
        .player-responsive1{
            @include devices(laptop_lg) {
                height: 175px !important;
            }
            @include devices(laptop_sm) {
                height: 180px !important;
            }
        }    
        .no-stream-found{
            @include devices(laptop_lg) {
                height: 175px !important;
            }
            @include devices(laptop_sm) {
                height: 180px !important;
            }
        }    
        .CameragridAssociateDiv{
            @include devices(laptop_lg) {
                height: 175px !important;
            }
        }
      }
}