/* All theme scss file import here */
@import '../src/Styles/breakpoints';
@import '../src/Styles/mixins';

@import '../src/components/Sidebar/sidebarLightTheme.scss';
@import '../src/components/Sidebar/sidebarDarkTheme.scss';
@import '../src/components/Topbar/topbarLightTheme.scss';
@import '../src/components/Topbar/topbarDarkTheme.scss';
@import '../src/pages/Dashboard/DashboardLightTheme.scss';
@import '../src/pages/Dashboard/DashboardDarkTheme.scss';
@import '../src/pages/Alerts/AlertLightTheme.scss';
@import '../src/pages/Alerts/AlertDarkTheme.scss';
@import '../src/pages/Configuration/ConfigurationLightTheme.scss';
@import '../src/pages/Configuration/ConfigurationDarkTheme.scss';
@import '../src/pages/Cameras/CameraLightTheme.scss';
@import '../src/pages/Cameras/CameraDarkTheme.scss';
@import '../src/pages/Cameras/PopupCardLightTheme.scss';
@import '../src/pages/Cameras/PopupCardDarkTheme.scss';
@import '../src/pages/Mosaic/MosaicLightTheme.scss';
@import '../src/pages/Mosaic/MosaicDarkTheme.scss';
@import '../src/pages/HealthMonitoring/HMLightTheme.scss';
@import '../src/pages/HealthMonitoring/HMDarkTheme.scss';
@import '../src/pages/HealthMonitoring/HMTableLightTheme.scss';
@import '../src/pages/HealthMonitoring/HMTableDarkTheme.scss';
@import '../src/pages/Reports/ReportLightTheme.scss';
@import '../src/pages/Reports/ReportDarkTheme.scss';
@import '../src/pages/NotificationMenu/NotificationContainerLightTheme.scss';
@import '../src/pages/NotificationMenu/NotificationContainerDarkTheme.scss';
@import '../src/pages/Profile/ProfileLightTheme.scss';
@import '../src/pages/Profile/ProfileDarkTheme.scss';
@import '../src/pages/Chatflow/ChatflowLightTheme.scss';
@import '../src/pages/Chatflow/ChatflowDarkTheme.scss';
@import '../src/pages/DownloadHistory/DownloadHistoryLightTheme.scss';
@import '../src/pages/DownloadHistory/DownloadHistoryDarkTheme.scss';



/* Commaon style use for every page start*/
      
    body.lightTheme {
       /*  font-family: 'Nunito Sans', sans-serif; */
       /*  color: #8a8a8a; */
        background-color: #f2f6fe !important;
    }

    body.darkTheme {
       /*  font-family: 'Nunito Sans', sans-serif; */
       /*  color: #000; */
        background-color: #423E47 !important;
    }
    body {
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: $background-color_8;
			border-radius: 100px;
		}
	}

    .mainDiv {
        width: 100%;
        display: inline-block;
        margin-top: 62px;
        height: calc(100vh - 65px);
    }

    .MainContentMargin {
        margin: 10px;
        height: 100%;
    }
    
    body::-webkit-scrollbar {
        width: 8px;
    }

    body::-webkit-scrollbar-thumb {
        background-color: #748b94;
        border-radius: 100px;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #748b94;
        border-radius: 100px;
    }

    #style-3::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
    }

    #style-3::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }

    #style-3::-webkit-scrollbar-thumb {
        background-color: #748b94;
        border-radius: 100px;
    }
     
    .online-color {
        // color: #8BB0F4;
        color: #7BB526 !important;
    }
    .offline-color {
        // color: #6C6C6C;
        color: #F32C22 !important;
    }
    /* Search reusable start */

    .inputSearchContainer {
        float: left;
    }
    .w25dot75rem {
        width: 100%;
    }
    .SearchIcon {
        position: absolute;
        right: 15px;
        bottom: 7.5px;
        top: 3px;
        cursor: pointer;
    }    
    .padR {
        padding-right: 12px;
    }

    .padL {
        padding-left: 12px;
    }

    .padT {
        padding-top: 12px;
    }

    .padB {
        padding-bottom: 12px;
    }
/* Search reusable end */
    
/* Commaon style use for every page end*/

.twoWayCommunication-and-hooter {
    text-align: end;
}
// .rs-calendar-time-dropdown {
//     background-color: black;
//     color: #ffffff;
// }
// .rs-calendar-time-dropdown-column-title {
//     background-color: black;
//     color: #ffffff;
// }