
$color_1: #fff;
$color_2: #1d1d1d;
$color_3: #717171;
$color_4: #0000ff;
$color_5: #cfcfcf;
$background-color_1: #fff;
$background-color_2: #0b70d1;
$background-color_3: #000000;
$background-color_4: #f2f6fe;
$box-shadow_1: 0 0 6px 0 rgba(0, 0, 0, 0.3);
$box-shadow_2: 0 2px 2px 0 rgba(0, 0, 0, 0.2);

.lightTheme{

    progress[value] {
        appearance: none; /* Needed for Safari */
        border: none; /* Needed for Firefox */
        color: $background-color_2; /* Fallback to a solid color */
      }
      
      /* WebKit styles */
      progress[value]::-webkit-progress-value {
        background-image: linear-gradient(
          to right,
          #ff8a00, $background-color_2
        );
        transition: width 1s linear;
      }
      
      /* Firefox styles */
      progress[value]::-moz-progress-bar {
        background-image: -moz-linear-gradient(
          right,
          #ff8a00, $background-color_2
        );
      }

    .rightcameradiv {
        @include floatRight;
        @include flexCenter;
        width: auto;
        justify-content: end;
        @include devices(laptop_xxl) {
            width: 36%;
        }  
        @include devices(laptop_xl) {
            width: 40%;
        }  
        @include devices(laptop_lg) {
            width: 45%;
        }  	
        @include devices(laptop_md) {
            width: 50%;
        }  	
    }
    .cam-search {
        @include floatLeft;
        border-radius: 8px;
        background-color: $background-color_1;
        padding-left: 10px;
        width: 22rem;
        .SearchViewInput {
            width: 95%;
        }
    }
    .mosaicdiv {
        width: auto;
        align-items: center;
        @include display-flex ;
        @include floatRight;
    }
    .playbackbuttonNewUI {
        width: 120px;
        height: 32px;
        border-radius: 7px;
        background-color: $background-color_2;       
        color: $color_1;
        margin-left: 5px;
        @include display-flex ; 
        justify-content: space-around;
        align-items: baseline;
    }
    .mosaicbuttonNewUI {
        width: 80px;
        height: 32px;
        border-radius: 7px;
        background-color: $background-color_2;
        color: $color_1;
        margin-left: 5px;
        @include display-flex ; 
        justify-content: space-around;
        align-items: baseline;
    }
    .downloadbuttonNewUI {
        padding: 0.3rem;
        border-radius: 7px;
        background-color: $background-color_2;
        color: $color_1;
        margin-left: 5px;
        @include display-flex ; 
        justify-content: space-around;
        align-items: baseline;
    }
    .playbackbuttonButtonText {
        font-size: 0.75rem;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        margin: auto;
        margin-left: 0;
    }
    .CameraGridNewUI {
        width: 98%;
        height: auto;
        padding: 0 0 6px;
        border-radius: 7px;
        box-shadow: $box-shadow_1;
        background-color: $background-color_1;
        
    }

    #slider {
        margin-top: 10px;
      }
      
      #slidertitle {
        display: none;
        position: absolute;
        cursor: default;
        top: 0;
        left: 0;
      }
      
      #container:hover > #slidertitle {
        display: block;
      }
      
    .cameraHoverMainDiv {
        position: relative;
        width: 100%;
        &:hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .CameraDiv1 {
        @include relativePosition;
        width: auto;
        min-width: 340px;
        margin: 0px;
        display: inline-block;
        :hover .cameraButtonsMainDiv {
            display: flex;
            bottom: 5px;
        }
    }
    .cameraButtonsMainDiv {
        display: none;
        position: absolute;
        bottom: 2px;
        background-color: rgba(0, 0, 0, 0.6);
        padding: 10px;
    }
    .CameraNameTextValue {
        width: 335px;
        font-size: 14px;
        letter-spacing: normal;
        text-align: left;
        color: $color_2;
        margin: 0;
        font-weight: 500;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .LabelDivWithButton {
      @include flexSpaceBetween;
    }
    .playbackbtn {
        background-color: transparent;
        border: 1px solid transparent;
        line-height: 45px;
        padding: 0;
    }
    .PlayBackBtnDiv {
        width: 30px;
        height: 30px;
        object-fit: contain;
        border-radius: 25px;
        box-shadow: $box-shadow_2;
        background-color: $background-color_2;
        @include flexCenter;
        margin-left: 5px;
        img {
            margin-right: 2px;
        }
    }
    .CameraDetailsText {
        height: 16px;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
        color: $color_3;
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
    }
    .CameraDetailsDiv {
        margin-top: 5px;
    }
    .HeightScrollCameraGrid {
        height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        margin-right: 15px;
        @include devices(laptop_lg) {
            height: calc(100vh - 132px);
        }
        @include devices(laptop_xs) {
            margin-right: 6px;
        }
    }
    .camera-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 300px;
        grid-gap: 10px;
        margin-bottom: 10px;
        margin-top: 5px;
        @include devices(laptop_xl) {
            grid-template-columns: repeat(3, 1fr);
        }  	
        @include devices(laptop_md) {
            grid-template-columns: repeat(3, 1fr);
        }  	
    }
    .camera-list .mb-0 {
        width: 150px;
    }
    .player-responsive1 {
        @include relativePosition;
        width: 100% !important;
        height: 220px !important;
        padding: 0.2rem 0.2rem;
        background-color: $background-color_3;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
        .player-responsive-fullScreen {
            @include absolutePosition;
            width: 100% !important;
            height: 100% !important;
            // max-height: 850px !important;
            padding: 0.2rem 0.2rem;
            background-color: $background-color_3;
        }
    .playbackcalendersection {
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: $box-shadow_1;
        border-radius: 3px;
        padding: 10px;
        margin: 5px;
        @include flexSpaceBetween;
    }
    .camera-checkbox {
        width: 22px;
        height: 22px;
        border-radius: 5px;
        border: 2px solid;
        display: inline-block;
        vertical-align: middle;
        padding: 2px;
        cursor: pointer;
        &:checked {
            content: "";
            padding: 2px;
            width: 22px;
            height: 22px;
            border-width: 0 3px 3px 0;
            transform: rotate(359deg);
            border-bottom: 10px solid $color_4;
            border-top: 10px solid  $color_4;
            border-bottom: 10px solid  $color_4;
            border-top: 10px solid  $color_4;
        }
        &:checked+.label-for-check {
            border: 4px solid red;
            box-shadow: 5px 10px red;
        }
    }
    .react-calendar{
        background-color: $background-color_1;
        width: 340px !important;
    }
    
    .playback {
        @include display-flex;
        flex-direction: column;
        padding-left: 1.5rem;
    }
    
    .playbackcameradiv {
        @include display-flex;
        padding-top: 0.5rem;
        flex-direction: row;
    }
    
    .playbackcameradiv_one {
        flex: 1
    }
    
    .playbackcameradiv_one-link {
        text-decoration: none;
    }
    
    .playbackcameradiv_one > p {
        font-size: 1.2rem;
        color: black;
    }
    
    .playbackcameradiv_three {
        margin-left: 0.2rem;
    }
    
    .playback__datepickers {
        @include display-flex;      
        align-items: center;
        gap: 1rem;
        height: 50px;
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        // box-shadow: $box-shadow_1;
        border-radius: 3px;
        padding-left: 10px;
    }
    // .playbackDatepickers {
    //     @include display-flex;      
    //     align-items: center;
    //     gap: 1rem;
    //     height: 50px;
    //     // background: #FFFFFF 0% 0% no-repeat padding-box;
    //     // box-shadow: $box-shadow_1;
    //     border-radius: 3px;
    //     padding-left: 10px;
    //     .singleInputDiv {
    //         @include display-flex; 
    //         flex-direction: row;
    //         border: 1px solid $color_5;
    //         padding: 5px;
    //         border-radius: 7px;
    //         width: 200px;
    //         justify-content: space-between;
    //     }
    // }
    
    .endDateStringSinglePlayback {
        padding-top: 0.5rem;
    }
    .singleInputDiv {
        @include display-flex; 
        flex-direction: row;
        border: 1px solid $color_5;
        padding: 5px;
        border-radius: 7px;
        width: 200px;
        justify-content: space-between;
    }
    .single-cam-playback-main {
        height: 700px;
        @include devices(laptop_xl) {
            height: 685px;
        }  
        @include devices(laptop_lg) {
            height: 535px;
        }  	
        @include devices(laptop_md) {
            height: 535px;
        } 
        @include devices(laptop_sm) {
            height: 405px;
        }  	
    }
    .single-cam-playback-sub {
        background-color: none !important;
        box-shadow: none !important;
    }
    .single-cam-playback-player {
        height: 100% !important;
        width: 100% !important;
    }
    .singleInput {
         border: none;
         background-color: transparent;
     }
     
     .singleInput:focus {
         outline: none;
     }
     .singlePlayebackHover {
        position: relative;
        height: 765px;
        @include devices(laptop_xxl) {
            height: 735px;
        } 
        @include devices(laptop_xl) {
            height: 735px;
        }
        @include devices(laptop_lg) {
            height: 585px;
        } 
        @include devices(laptop_md) {
            height: 585px;
        }
        @include devices(laptop_sm) {
            height: 465px;
        }
        &:hover .mosaicButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .singleCameraHover {
        height: calc(100% - 85px);
        flex: 1 1 auto;
        // overflow-y: auto;
        &:hover .mosaicButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .changeColor {
        cursor: pointer;
        fill: black;
    }

    .changeColor:hover {
        cursor: pointer;
        fill: #0B70D1;
    }
    
    .playbackicon {
        margin: auto;
    }
    
    .no-stream-found {
        height: 220px;
        border-radius: 7px 7px 0px 0px;
        border-bottom: 1px solid #e2e2e2;
    }

    .CameraNameText1 {
        font-size: 1rem;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.8rem;
        letter-spacing: normal;
        text-align: left;
        color: #0f6689;
        margin-bottom: 0rem;
    }
    
    .player-responsive1 > video {
        @include relativePosition;
    }

        .player-responsive-fullScreen>video {
            @include relativePosition;
        }
    .mosaic-nostream-img {
        height: 100%;
        border-radius: 7px 7px 0px 0px;
    }

    .cam-name {
        width: 100% !important;
    }

    .location-name {
        width: 125% !important;
    }

    .cam-pg {
        color: #8A8A8A !important;
    }

    .mosaic-pg {
        color: #ffffff;
    }        
    #endDateStringSinglePlayback{
        margin-bottom: 0px;
    }

    .single-cam-playback-controls {
        background-color: #343434;
        margin-top: 0.1rem;
        border-radius: 0.5rem;
        height: 45px;
        @include display-flex; 
        flex-direction: row;
        color: white;
        padding: 0rem 0.5rem;
        font-size: 0.8rem;
    }
    .single-cam-playback-hover-controls {
        width: 100%;
        margin-top: 0.1rem;
        border-radius: 0.5rem;
        height: 45px;
        @include display-flex; 
        flex-direction: row;
        color: white;
        font-size: 0.8rem;
    }
    .single-cam-playback-controls__left {
        width: 3%;
    }

    .controls_seconds {
        font-size: 0.7rem;
        color: #B0B0B0;
    }

    .controls_pause {
        border: 2px solid $color_1;
        border-radius: 50%;
        display: grid;
        place-items: center;
        height: 35px;
        margin-top: 10%;
        width: 35px;
        margin-left: 10%;
        font-weight: bold;
        cursor: pointer;
    }

    .single-cam-playback-controls__right {
        flex: 1;
        @include display-flex; 
        flex-direction: column;
        padding: 0.5rem;
    }

    .single-cam-playback-controls__right-upper {
        @include display-flex; 
        flex-direction: row;
        justify-content: space-between;

    }
    .Singleplaybackbuttons {
        float: left;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .SingleplaybackButtonDiv {
        width: 35px;
        height: 35px;
        object-fit: contain;
        border-radius: 25px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
        background-color: #343434;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
    }
    .controls_video {
        @include display-flex; 
        gap: 0.5rem;
    }
    .controls_seekbar {
        width: 100%;
    }
    video::-webkit-media-controls-panel {
        background-image: linear-gradient(transparent, transparent) !important;
    }
    .table-fixed tbody tr:hover td {
        background-color: $background-color_4;
    }
    .singleplaybackexportModal {
        z-index: 99999;
        .modal-content{
            height: 535px;
            width: 335px;
        }
    }
    .ExportModal {
        z-index: 99999;
        .modal-content {
            height: 605px;
            width: 335px;
        }
    }
    .singleplaybackNoStream {
        height: 100%;
        flex: 1 1 auto;
        overflow-y: auto;
    }
    .singleplaybackNoStreamdiv {
        height: 750px;
        @include devices(laptop_xl) {
            height: 730px;
        }  
        @include devices(laptop_lg) {
            height: 585px;
        }  	
        @include devices(laptop_md) {
            height: 730px;
        }
        @include devices(laptop_sm) {
            height: 445px;
        }
    }

    // .modal-lg {
    //     max-width: 1200px !important;
    // }
    #output {
        width: auto;
        max-width: 1200px;
        overflow: auto;
        height: auto;
        max-height: 600px;
        @include devices(laptop_sm) {
            height: auto;
            max-height: 458px !important;
            overflow: auto;
        }
    }

    .CameraLeftContainer {
        width: 27%;
        float: left;
        background-color: #ffffff;
        border-radius: 13px;
        border: 1px solid #707070;
        border-radius: 10px;
        margin-right: 15px;
        display: flex;
        flex-direction: column;
        .SearchViewInput1 {
            @include devices(laptop_sm) {
                width: 90%;
            }
            @include devices(laptop_md) {
                width: 90%;
            }
            @include devices(laptop_xl) {
                width: 90%;
            }
        }
    }
    .CameraFilterList {
        /* height: 620px; */
        margin: 10px 5px 10px 10px;
        overflow: auto;
        padding-right: 5px;
        scroll-behavior: smooth;
        flex: 1 1 auto;
        overflow-y: auto;
       /*  @include devices(laptop_sm) {
            height: 290px;
        }
        @include devices(laptop_md) {
            height: 290px;
        }
        @include devices(laptop_xl) {
            // height: 570px;
        } */
    }
    .CameraRightContainer {
        width: 72%;
       /*  margin-left: 15px; */
        position: relative;
        float: left;
        background-color: #ffffff;
        border-radius: 13px;
        float:right;
        // border: 1px solid #707070;
    }
    .saveCameraFilterModal .modal-content {
        width: 520px;
    }
    .camerafilter2x2 {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-gap: 3px;
        // width: 100vw;
        height: 70vh;
        padding: 0px 10px;
        overflow: hidden;
        .HeightChildDiv {
            &:nth-child(1) {
                grid-column: auto / span 3;
                grid-row: span 3;
            }
            &:nth-child(2) {
                grid-column: auto / span 3;
                grid-row: span 3;
            }
            &:nth-child(3) {
                grid-column: auto / span 3;
                grid-row: span 3;
            }
            &:nth-child(4) {
                grid-column: auto / span 3;
                grid-row: span 3;
            }
        }
        :hover .mosaicButtonsMainDiv {
            display: flex;
            bottom: 1px;
        }
    }
    .filterSelectedCount {
        background: #0D70D1 0% 0% no-repeat padding-box;
        border-radius: 25px;
        color: #FFFFFF;
        width: 40px;
        text-align: center;
    }
    .soundContainer {
        h4 {
            margin: 0px 5px;
            font-size: 18px;
            line-height: 35px;
            color: #777777;
        }
        .multi-select {
            :focus-within {
                border-color: #0b70d4 !important;
            }
        }
        .rs-picker-default .rs-picker-toggle.rs-btn{
            position: unset;
        }

    }
    .dropdownSelectionContainer{
        .dropdown-container, .dropdown{
            background: #E9F2FF !important;
            border: 1px solid #0D70D1 !important;
            .gray{
                color: #0D70D1 !important;
            }
            .btn-light{
                color: #0D70D1 !important;
                font-weight: 600;
                padding-right: 45px;
            }
            .dropdown-heading-value{
                color: #0D70D1 !important;
                font-weight: 600;
                font-family: Arial;
            }
        }
        
    }
    .multi-select {
        min-width: 10%;
        width: auto !important;
        margin: 0px 5px 9px 5px !important;
        height: 35px;
       
        @include devices(laptop_lg){
            min-width: 15%;
        }
        @include devices(laptop_sm){
            min-width: 13%;
        }
        
        /* 
        @include devices(laptop_md){
            min-width: 15%;
        }
        @include devices(laptop_sm){
            min-width: 15%;
        }
        @include devices(laptop_xs){
            min-width: 15%;
        } */
        .dropdown-container{
            height: 35px;
            background: #FAFAFA;
            border: 1px solid #DBDBDB;
            border-radius: 10px;
        }
        
        
        .dropdown-heading{
            height: 35px;
            font-size: 13px;
            .gray{
                color: #454545;
                font-size: 13px;
                line-height: 20px;
                font-family: Arial;
            }
        }
        .selectedCount{
            width: auto;
            padding: 2px 7px;
            margin-left: 5px;
        }
    }
    .cameraDetailDiv {
        // box-shadow: rgb(112, 111, 111) 0px 0px 5px 0px inset, 0 0 6px 0 rgba(0, 0, 0, 0.3);
        // background-color: #1F1A24;
        // .playback__datepickers {
        //     background-color: transparent;
        // }
        height: 65px;
        margin-bottom: 20px;
    }
    .clearSelection {
        text-align: right;
        text-decoration: underline;
        font: normal normal normal 13px/15px Segoe UI;
        letter-spacing: 0px;
        color: #1A73E8;
        opacity: 1;
        cursor: pointer;
    }
    .selectCountClearSelection {
        display: flex;
        justify-content: space-between;
        padding: 5px;
    }
    .IconsButtonDiv {
        position: relative;
        width: 50px;
        height: 35px;
        line-height: 10px;
        border-radius: 7px;
        opacity: 1;
        background-color: #0b70d1;
        margin-left: 5px;
        &:hover {
			background: #005aad radial-gradient(circle, transparent 1%, #005aad 1%) center/15000%;
  			transition: background 0.8s;
		}
		&:active {
			background-color: #ffffff;
			background-size: 100%;
			transition: background 0s;
		  }
    }
    .alertInprogressDetail {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            margin: 0px !important;
        }
        span {
            font-weight: bold;
            margin-left: 3px;
        }
    }
    .MainContainerSectionCamera{
        position: relative;
        display: block;
        border: 1px solid #707070;
        border-radius: 10px;
        padding: 7px;
        display: flex;
        flex-direction: column;
        height: 100%;
    }
    .cameraFilterPage{
        height: calc(100vh - 140px);
        display: flex;
        flex-direction: column;
    }
    .FilteredCameraListDiv{
        display: flex;
        flex-wrap: wrap;
        flex: 0 1 auto;
    }
    .ListFilterCamera{
        padding: 10px;
        width: 100%;
        flex: 1 1 0;
        display: flex;
        overflow: hidden;
        .selectCountClearSelection{
            padding: 5px 10px;
        }
    }
    // .Button_module_buttonChildren {
    //     overflow: hidden;
    //     white-space: nowrap;
    //     text-overflow: ellipsis;
    // }
    // .Tooltip_module_tooltip {
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     left: 50%;
    //     color: #fff;
    //     background: rgba(0,0,0,.9);
    //     font-size: 12px;
    //     letter-spacing: 0;
    //     bottom: 2.66667em;
    //     padding: 0.66667em 1em;
    //     height: 2.66667em;
    //     line-height: 1.25em;
    //     border-radius: 4px;
    //     transition: opacity .15s ease-out;
    //     position: absolute;
    //     font-weight: 700;
    //     opacity: 0;
    //     pointer-events: none;
    //     white-space: pre;
    //     transform: translateX(-50%);
    // }

    .loader {
        text-align:center;
        display:flex;
        align-items:center;
        justify-content:center;
      }
      .loader h1 {
        color:white !important;
        font-family: 'Segoe UI';
      }
      .bullets{
        animation: dots 2s steps(3, end) infinite;
      }
      
      @keyframes dots {
        0%, 20% {
          color: rgba(0,0,0,0);
          text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
        40% {
          color: white;
          text-shadow:
            .25em 0 0 rgba(0,0,0,0),
            .5em 0 0 rgba(0,0,0,0);}
        60% {
          text-shadow:
            .25em 0 0 white,
            .5em 0 0 rgba(0,0,0,0);}
        80%, 100% {
          text-shadow:
            .25em 0 0 white,
            .5em 0 0 white;}
        }

        .loaderr {
            text-align:center;
            display:flex;
            align-items:center;
            justify-content:center;
            height: calc(100% - 95px);
          }
          .loaderr h1 {
            color:white !important;
            font-family: 'Segoe UI';
          }
          .dots-2 {
            display: inline-block;
            width: 20px;
            aspect-ratio: 4;
            background: radial-gradient(circle closest-side,#000 90%,#0000) 0/calc(100%/3) 100% no-repeat;
            animation: d2 1s steps(3) infinite;
          }
          @keyframes d2 {to{background-position: 150%}}
}